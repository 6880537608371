import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import EditorContainer from "../admin/editor.container.component";
var moment = require('moment-timezone')

export default class BiographyEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      biography: [],
      loaded: false,
      currentEditId: 0,
      editing: false
    };
    this.setCurrentEdit = this.setCurrentEdit.bind(this);
    this.getBiography = this.getBiography.bind(this);
    this.rerender = this.rerender.bind(this);
  }

  async componentDidMount() {
    console.log("Biography component mounted")
    this.getBiography();
  }

  rerender() {
    console.log("rerender called")
  }

  getBiography() {
    console.log("get biography called")
    fetch(`${process.env.REACT_APP_API_URL}/biography`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      console.log("something")
      console.log(res.length)
      if(res.length === 0) {
        this.setCurrentEdit(0)
        this.setState({loaded: true})
      }
      else {
        console.log("hit else")
        res.map(({id}) => {
          this.setCurrentEdit(id)
          this.setState({loaded: true})
        })
      }
    }).catch((error) => alert(error))
  }

  setCurrentEdit(id) {
    if(id === 0) {
      this.setState({editing: false})
    }
    else {
      this.setState({editing: true})
    }
    this.setState({currentEditId : id})
  }

  render() {
    console.log(this.state.currentEditId)
    return (
      <div>
        {this.state.loaded && (
          <div style={{borderStyle: "groove", padding: 10, borderRadius: 10, marginTop: "50px"}}>
            <h2>Edit Biography Page</h2>
        <EditorContainer id={this.state.currentEditId} renderEdit={this.state.editing} getEndpoint="biographyById" submitEndpoint="biography" deleteEndpoint="" rerender={this.rerender} hideDescription={true}/>
        </div>
        )}
      </div>
    )
  }
}