import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export default class Contact extends Component {

  constructor(props) {
    super();
    this.state = {
      apiResults: {},
      isLoaded: false
    };
  }

  async componentDidMount() {
    this.getApiResults();
  }

  getApiResults() {
    fetch(`${process.env.REACT_APP_API_URL}/contact`, { mode: 'cors' })
      .then(response => response.json())
      .then(res => {
        this.setState({ apiResults: res })
        this.setState({ isLoaded: true })
      })
      .catch((error) => {
        console.log("there was an error")
      })
  }

  render() {

    if (this.state.isLoaded) {
      var data = this.state.apiResults;
      return (

        <div>
          {data.map(({ title, email, address, instagramhandle, websiteurl, description, mobile, image  }) => (
            <div key={title}>
            <Card style={{ width: '80vw', textAlign: "left" }}>
              <Card.Body>
              {title !== '' && title !== null && <Card.Text style={{ display: "flex", alignSelf: "left", fontSize: "22px" }}>
                  {title}
                </Card.Text>}
                <Card.Text>
                  {image != '' && image != null && <img src={image} style={{maxWidth: "200px", display: "block"}}></img>}
                </Card.Text>
                {description !== '' && description !== null && <Card.Text style={{ display: "flex", alignSelf: "left" }}>
                  {description}
                </Card.Text>}
                {mobile !== '' && mobile !== null && <Card.Text style={{ display: "flex", alignSelf: "left" }}>
                  Contact: {mobile}
                </Card.Text>}
                {email !== '' && email !== null && <Card.Text style={{ display: "flex", alignSelf: "left" }}>
                  Email: &nbsp; <a href={"mailto:" + email}> {email}</a>
                </Card.Text>}
                {address !== '' && address !== null && <Card.Text style={{ display: "flex", alignSelf: "left" }}>
                  Address: {address}
                </Card.Text>}
                {instagramhandle !== null && <Card.Text style={{ display: "flex", alignSelf: "left" }}>
                <img style={{maxHeight: "20px", marginTop: "2px"}} src="/instagram.png"></img><p style={{marginLeft: 5}}>Follow {title} on <a style={{marginLeft:"3px"}} target="_blank" href={`https://instagram.com/${instagramhandle}`}>Instagram</a></p>
                </Card.Text>}
                {websiteurl !== '' && websiteurl !== null && <Card.Text style={{ display: "flex", alignSelf: "left" }}>
                  <a href={websiteurl}>Visit {title} Website</a>
                </Card.Text>}
              </Card.Body>
            </Card>
            <br/>
            </div>
          ))}

        </div>
      )

    }

    else {
      return (
        <div>

        </div>
      );
    }
  }
}