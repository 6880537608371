import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button, Dropdown } from 'react-bootstrap'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
var Carousel = require('react-responsive-carousel').Carousel;

export const ShopItemModal = (props) => {
  const [saleItem, setSaleItem] = useState(null)
  const [soldOut, setSoldOut] = useState(true)
  const [itemType, setItemType] = useState(true)
  const [currentSelectedItemType, setCurrentSelectedItemType] = useState(true)


  useEffect(() => {
    getSaleItem()
  }, [])

  useEffect(() => {
    if (saleItem != null) {
      var soldOut = false
      var cart = JSON.parse(localStorage.getItem('cart'))
      const alllItemTypes = JSON.parse(saleItem[0].item_types)
      const defaultItemType = alllItemTypes.find(item => item.default) || alllItemTypes[0]
      setItemType(defaultItemType)

      saleItem.map(({ id, remaining }) => {
        if(remaining === 0 && id === saleItem[0].id) soldOut = true
        else if(saleItem[0].id === id && remaining <= cart[id])
        soldOut = true;
      })
      setSoldOut(soldOut)
    }
  }, [saleItem])

  function getSaleItem() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: props.product.id }),
      mode: 'cors' 
    };
    fetch(`${process.env.REACT_APP_API_URL}/saleItem`, requestOptions)
    .then(response => response.json())
    .then(res => {
      setSaleItem(res)
    })
      .catch((error) => {
      props.modalClosedCallback()
      console.log("there was an error")
      console.log(error)
    })
  }

  return (saleItem && (
    <Modal centered={true} enforceFocus={false} show={true} onHide={props.modalClosedCallback}>
      <Modal.Header closeButton>
        <Modal.Title>Product Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign: 'center'}}>
        <h2>{saleItem[0].title}</h2>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Carousel autoPlay={true} interval={5000} showThumbs={false} showArrows={true} transitionTime={1000} infiniteLoop={true}>
          {saleItem.map(({ image_bin }) => (
            <div key={image_bin}>
              <img src={image_bin.replace("upload/", "upload/c_pad,h_450,w_766/")} alt="/loading.gif" style={{maxHeight: "450px", maxWidth: "99%"}}/>
            </div>
            ))}
          </Carousel>
        </div>
        <div>{saleItem[0].description}</div>
        {JSON.parse(saleItem[0].item_types).map(itemType => {
          var cart = JSON.parse(localStorage.getItem('cart'))
          var itemsInCart = 0
          if (cart[saleItem[0].id] && cart[saleItem[0].id][itemType.id]) {
            itemsInCart = cart[saleItem[0].id][itemType.id].quantity
          }
          const resolvedItemType = JSON.parse(saleItem[0].item_types).find(foundItemType => foundItemType.id == itemType.id)
          const isSoldOut = (parseInt(resolvedItemType?.remaining || 0) - itemsInCart) <= 0
          const itemCount = (cart[saleItem[0].id] && cart[saleItem[0].id][itemType.id]?.quantity) || 0
                  return (<Button disabled={ isSoldOut } variant={isSoldOut ? 'outline-danger' : currentSelectedItemType === itemType.id ? 'primary' : 'outline-dark'} onClick={() => setCurrentSelectedItemType(itemType.id)} style={{ margin: '3px' }}>
                    <div>
                    {itemType.size}
                    </div>
                    {itemCount > 0 &&
                      <div style={{fontSize: '10px'}}>
                      { itemCount } In Cart
                      </div>}
                    {isSoldOut &&
                      <div style={{fontSize: '12px', fontWeight: 800}}>
                        Sold Out
                      </div>}
                    <div style={{fontSize: '12px', fontWeight: 800}}>
                        $ {resolvedItemType.price}
                      </div>
                  </Button>)
                })}
      </Modal.Body>
      <Modal.Footer>
        {soldOut && <Button variant="outline-danger" disabled>Sold Out</Button>}
        <Button variant="secondary" onClick={props.modalClosedCallback}>
          Close
        </Button>
        {!soldOut && <Button variant="primary" onClick={() => props.addItemToCartCallback(saleItem[0].id, itemType.id)} >
          Add to Cart
        </Button>}
      </Modal.Footer>
      </Modal>)
  );
};
