import React, {Component} from "react";
import {Button} from 'react-bootstrap'
import Table from 'react-bootstrap/Table'


export default class Cart extends Component {
  constructor(props) {
    super();
    this.state = {
      amount: 0,
      shippingCost: 0,
      storedCart: {},
      checkoutCart: null,
      shopItems: null,
      showItemAddedModal: false
    }
    this.cartUpdated = this.cartUpdated.bind(this)
    this.itemRemoved = this.itemRemoved.bind(this)
  }

  componentDidMount() {
    var storedStateCart = localStorage.getItem('cart')
    if (storedStateCart) {
      this.setState({storedCart: JSON.parse(localStorage.getItem('cart'))})
      this.cartUpdated()
    }
    this.getShopItems()
  }

  getShopItems() {
    fetch(`${process.env.REACT_APP_API_URL}/saleItems`, {mode: 'cors'})
    .then(response => response.json())
    .then(res => {
      this.setState({ shopItems: res }, () => {
        this.cartUpdated();
    })
    })
    .catch((error) => {
      console.log("there was an error")
    })
  }

  itemRemoved(id, itemTypeId) {
    var cart = JSON.parse(localStorage.getItem('cart'))
    if (cart[id][itemTypeId] <= 1) {
      delete(cart[id][itemTypeId])
    }
    else {
      cart[id][itemTypeId].quantity = cart[id][itemTypeId].quantity - 1
    }
    localStorage.setItem('cart', JSON.stringify(cart))
    this.cartUpdated()
  }

  itemAdded(id, itemTypeId) {
    if(!this.isSoldOut(id, itemTypeId)) {
      var cart = JSON.parse(localStorage.getItem('cart'))
      cart[id][itemTypeId].quantity += 1
      localStorage.setItem('cart', JSON.stringify(cart))
      this.cartUpdated()
    }
  }

  isSoldOut(productId, itemTypeId) {
    var soldOut = false
    if(this.state.shopItems) {
      var cart = JSON.parse(localStorage.getItem('cart'))
      var itemsInCart = 0
      if (cart[productId] && cart[productId][itemTypeId]) {
        itemsInCart = cart[productId][itemTypeId].quantity
      }
      var shopItem = this.state.shopItems.find(shopItem => shopItem.id == productId)
      const itemType = JSON.parse(shopItem.item_types).find(itemType => itemType.id == itemTypeId)
      return ((parseInt(itemType?.remaining || 0)) - itemsInCart) <= 0
    }
    return soldOut;
  }

  cartUpdated() {
    if(this.state.shopItems) {
      var amount = 0
      var shippingCost = 0
    var items = []
      var cart = JSON.parse(localStorage.getItem('cart'))
    Object.keys(cart).map(function(keyName, keyIndex) {
      this.state.shopItems.map(({ id, item_types, price, image_bin, title, description, can_ship, shipping_cost }) => {
        JSON.parse(item_types).forEach(itemType => {
          if (id == keyName && cart[keyName][itemType.id]?.quantity > 0) {
          if (shipping_cost != null) {
            shippingCost += shipping_cost * cart[keyName][itemType.id].quantity
          }
          amount += itemType.price * cart[keyName][itemType.id].quantity
          items.push({id: id, price: itemType.price, itemTypeId: itemType.id, image_bin: image_bin, title: title, description: description, quantity: cart[keyName][itemType.id].quantity, shipping: can_ship, shipping_cost: shipping_cost})
        }
        })
      })
    }.bind(this))
    this.setState({checkoutCart: items})
    this.setState({amount: amount, shippingCost: shippingCost})
  }
  }

render() {
  return (
    <div className="container">
      <div style={{borderStyle:"solid", borderRadius:"5px", borderWidth: "1px", borderColor: "lightgray"}}>
        <div style={{borderBottom: "3px solid black", textAlign: "left"}}><span style={{display: "flex", margin: "10px", paddingLeft  : "20px", fontSize: "20px"}}>Shopping Cart</span></div>
        <Table borderless>
        <thead>
      <tr>
      <th>Product</th>
        <th>Price</th>
        <th>Quantity</th>
      </tr>
    </thead>
        <tbody>
      {this.state.checkoutCart !== null && this.state.checkoutCart.length <= 0 && (window.location.pathname = '/shop')}
      {this.state.checkoutCart !== null && this.state.checkoutCart.length > 0 && (
        this.state.checkoutCart.map((product, index) => (
          <tr key={{index}} style={{borderTop: "1px solid lightgray"}}>
            <td><img style={{ height: "100px" }} src={product.image_bin}></img><div>{product.title}</div>{!product.shipping && <div style={{ fontSize: "10px", color: 'red' }}> Shipping unavailable for this product, must be collected. </div> }</td>
            <td><span>${(product.price)*product.quantity}</span><span style={{fontSize: "10px", display: "block"}}>${product.price} each</span></td>
            <td><div style={{display: "inline-flex", alignItems: "center"}}><div onClick={() => this.itemRemoved(product.id, product.itemTypeId)} style={{borderStyle: "solid", borderRadius: "5px", borderWidth: "1px", width: "30px", height: "30px", marginRight: "5px", cursor: "pointer"}}>-</div>{product.quantity}<div onClick={() => this.itemAdded(product.id, product.itemTypeId)} style={{borderStyle: "solid", borderWidth: "1px", height: "30px",  borderRadius: "5px", width: "30px", marginLeft: "5px", cursor: this.isSoldOut(product.id, product.itemTypeId)? "not-allowed" : "pointer"}}>+</div></div></td> 
          </tr>
        ))
      )}
      </tbody>
      </Table>
        <div style={{ borderTop: "3px solid black" }}></div>
        {this.state.shippingCost !== 0 && <div>Shipping: ${(this.state.shippingCost / 100).toFixed(2)}</div>}
        <div>Total: ${ ((this.state.shippingCost/100) + (this.state.amount)).toFixed(2) }</div>
      </div>
      <Button onClick={() => window.location.pathname = '/shop'} variant="secondary" style={{marginTop: "15px"}}>Back</Button>
      <Button onClick={() => window.location.pathname = '/checkout'} style={{marginLeft: "5px" , marginTop: "15px"}}>Checkout</Button>
    </div>
  );
}
}