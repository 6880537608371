import React from 'react'
import "../css/accordion.image.css"
import ModalComponent from "./exhibition.modal.component"


export default class Collection extends React.Component {
constructor(props) {
  super();
  this.state = {
    apiResults: {},
    exhabitionId: 0,
    displayModal: false
  };
}

onClickHandler(exhibition_id) {

  console.log('click' + exhibition_id)
  this.setState({exhabitionId: exhibition_id})
  this.setState({displayModal: true})
}

handleModalClose() {
  this.setState({displayModal: false})
}

  render() {
    var images = this.props.images
    return (
      <div>
      {this.state.displayModal && <ModalComponent CloseHandler={() => this.handleModalClose()} ExhibitionId={this.state.exhabitionId} />}
      <div className="accordion">
        <ul>
          {images.map(({ image_bin, image_name, image_description, id }) => (
            <li onClick={() => this.onClickHandler(id)}>
              <div>
                <span id="ImageArea">
                  <img style={{maxWidth: 675 , maxHeight: 450}} src={image_bin.replace("upload/", "upload/c_scale,w_675,h_450/")}/>
                </span>
                <a href="#">
                  <h2>{image_name}</h2>
                  <p>{image_description}</p>
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
      </div>
    )
  }
}