import React, { Component } from "react";

import 'react-responsive-carousel/lib/styles/carousel.min.css'
var Carousel = require('react-responsive-carousel').Carousel;


export default class ExhibitionCarousel extends Component {
    render() {
      var data = this.props.data
        return (
          <div>
            <Carousel autoPlay={true} interval={5000} showArrows={true} transitionTime={1000} infiniteLoop={true}>
              {data.map(({ image_bin, image_name, image_description }) => (
                <div key={image_name}>
                    <h2>{image_name}</h2>
                    <span style={{color: "grey", fontSize: "10px"}}>{image_description}</span>
                    <img src={image_bin.replace("upload/", "upload/c_pad,h_450,w_766/")} alt="/loading.gif" style={{maxHeight: "450px", maxWidth: "99%"}}/>
                </div>
              ))}
            </Carousel>
           </div>
        );
    }
}
