import React, { Component } from "react";
import { Redirect } from "react-router";
import "../css/login.css";

export default class Login extends Component {

    constructor(props) {
        super();
        this.state = {
          Email: '',
          Password: '',
          errors: {
              Email: null,
              Password: null
          },
          LoginValid: false
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);
        if(this.state.Email === '') {
            this.state.errors.Email = "Email cannot be empty";
            this.state.errors.Password = null;
            this.forceUpdate();
        }
        else if(this.state.Password === '') {
            this.state.errors.Password = "Password cannot be empty";
            this.state.errors.Email = null;
            this.forceUpdate();
        }
        
        else 
        {

        fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(this.state)
          }).then(response => response.json())
          .then((jsonData) => {
            if(jsonData.status === "Error") {
                this.parseErrors(jsonData);
            }
            else {
            // jsonData is parsed json object received from url
            localStorage.setItem('token', jsonData.token);
            this.setState({LoginValid: true});
            }
          })
          .catch((error) => {
            alert("Unexpected error occoured, please try again later");
            console.error(error);
          })
        }
      }

      parseErrors(error) {
          this.state.errors.Email = null;
          this.state.errors.Password = null;

          if(error.message === "Email") {
              this.state.errors.Email = error.details[0];
          }
          else if(error.message === "Password") {
              this.state.errors.Password = error.details[0];
          }
          this.forceUpdate()
      }
    
      handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
          [name]: value
        });
      }

    render() {
        if(this.state.LoginValid) return <Redirect to="/admin"></Redirect>
        return (
            <div id="LoginPage">
                <div className="body">
            <div className="auth-wrapper">
            <div className="auth-inner">
            <form onSubmit={this.handleSubmit}>
                <h3>Sign In</h3>

                <div className="form-group">
                    <label>Email address</label>
                    <input onChange={this.handleInputChange} value={this.state.Email} name="Email" type="email" className="form-control" placeholder="Enter email" />
                    <div className="input-feedback">{this.state.errors.Email}</div>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input onChange={this.handleInputChange} value={this.state.Password} name="Password" type="password" className="form-control" placeholder="Enter password" />
                    <div className="input-feedback">{this.state.errors.Password}</div>
                </div>
                <br/>
                <button type="submit" className="btn btn-primary btn-block">Submit</button>
            </form>
            </div>
            </div>
            </div>
        </div>
        );
    }
      
}