import React, { Component } from "react";

export default class News extends Component {
  constructor(props) {
    super();
    this.state = {
      html: [],
      loaded: false,
      page: props.page,
      pageText: props.pageText
    };
    this.getRawHtml();
  }

  getRawHtml() {
    fetch(`${process.env.REACT_APP_API_URL}/news?page=${this.state.page}`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      this.setState({ html: res })
      this.setState({loaded: true})
    })
  }

  render() {
    return (
    <div>
      {this.state.pageText && this.state.pageText !== "" && (<div style={{borderStyle:"solid", padding:"10px", borderRadius:"3px", borderWidth:"1px", borderColor:"lightgray", paddingLeft: "20px", paddingRight: "20px", textAlign: "left", whiteSpace: "pre-line", fontSize: "12px"}}>
          {this.state.pageText}
        </div>)}
      <div className="container" style={{textAlign: "left"}}>
        {this.state.loaded && this.state.html.map(({ html }) => (
          <div className="post" style={{overflowWrap: "break-word"}}>
                <div dangerouslySetInnerHTML={{__html: html}}>
                </div>
                <hr style={{display: "block", height: "1px", border: 0, borderTop: "1px solid #000000", margin: "1em 0", padding: 0}}/>
                </div>
              ))
    }
        </div>
        </div>
    )
  }
}