import React from "react"
import { Form, Button, Row, Col } from "react-bootstrap"
import { SimpleModal } from "../modal.simple.component";
import TextBoxEditor from "./textbox.editor.component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default class SiteSettings extends React.Component {
  constructor(props) {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      homePageReviewText: '',
      homePageCiteText: '',
      instagramHandle: '',
      facebookUsername: '',
      shopEnabled: false,
      isLoaded: false,
      shopNotifyEmail: '',
      shopReplyEmail: '',
      showModal: false,
      modalTitle: '',
      modalBody: '',
      homepageIdCenter: null,
      homepageIdLeft: null,
      homepageLeftDisplayText: '',
      homepageCenterDisplayText: '',
      pages: props.pages
    };
    this.populateState()
    this.modalClosedCallback.bind(this)
  }

  populateState() {
    fetch(`${process.env.REACT_APP_API_URL}/siteDetails`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      if(res.length > 0) {
        var results = res[0]
        console.log(results)
        this.setState({ firstName: results.first_name, lastName: results.last_name, homePageReviewText: results.home_review_text, homePageCiteText: results.home_review_cite, instagramHandle: results.instagram_handle, facebookUsername: results.facebook_username, shopEnabled: results.shop_enabled, shopReplyEmail: results.shop_reply_email, shopNotifyEmail: results.shop_notify_email, homepageIdCenter: results.footer_center_page_id, homepageIdLeft: results.footer_left_page_id, homepageLeftDisplayText: results.footer_left_display_name, homepageCenterDisplayText: results.footer_center_display_name, isLoaded: true })
      }
    })
    .catch((error) => {
      console.log("there was an error")
    })
  }

  submitForm() {
    const siteSettingsRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: localStorage.getItem("token"), firstName: this.state.firstName, lastName: this.state.lastName, homeReviewText: this.state.homePageReviewText, homeReviewCite: this.state.homePageCiteText, instagramHandle: this.state.instagramHandle, facebookUsername: this.state.facebookUsername, shopEnabled: this.state.shopEnabled, shopNotifyEmail: this.state.shopNotifyEmail, shopReplyEmail: this.state.shopReplyEmail, homepageIdLeft: this.state.homepageIdLeft, homepageIdCenter: this.state.homepageIdCenter, homepageLeftDisplayText: this.state.homepageLeftDisplayText, homepageCenterDisplayText: this.state.homepageCenterDisplayText })
    }
    fetch(`${process.env.REACT_APP_API_URL}/updateSiteDetails`, siteSettingsRequestOptions)
    .then(res => res.status === 200 ? this.showModal("Alert", "Site Settings Changes saved") : this.showModal("Alert", "There was an issue saving site settings")).catch(error => this.showModal("Alert", `There was an error ${error}`))

  }

  showModal(title, body) {
    if (this.state.showModal) {
      this.setState({ modalBody: this.state.modalBody + ', ' + body })
    }
    else {
      this.setState({ showModal: true, modalTitle: title, modalBody: body })
    }
  }

  modalClosedCallback() {
    this.setState({showModal: false, modalTitle: '', modalBody: ''})
  }


  render () {
    return (
      <div>
        <SimpleModal title={this.state.modalTitle} body={this.state.modalBody} show={this.state.showModal} modalClosedCallback={this.modalClosedCallback.bind(this)} />
      {this.state.isLoaded && 
      <div style={{borderStyle: "groove", padding: 10, borderRadius: 10, marginTop: "50px"}}>
            <h2>Edit Site Settings</h2>
            <Form>
              <Row>
                <Col md>
                <Form.Label>Your First Name</Form.Label>
                <OverlayTrigger placement="top" overlay={<Tooltip>This text is used in many places around the site, such as the header, contact information on the home page.</Tooltip>}>
                <Form.Control 
                type="text" 
                placeholder="First Name"
                defaultValue={this.state.firstName}
                onChange={e => this.setState({ firstName: e.target.value })} 
                />
                </OverlayTrigger>
                </Col>
                <Col md>
                <Form.Label>Your Last Name</Form.Label>
                <OverlayTrigger placement="top" overlay={<Tooltip>This text is used in many places around the site, such as the header, contact information on the home page.</Tooltip>}>
                <Form.Control
                type="text" 
                placeholder="Last Name"
                defaultValue={this.state.lastName}
                onChange={e => this.setState({ lastName: e.target.value })} 
                />
                </OverlayTrigger>
                </Col>
                <Col md>
                <Form.Label>Your Instagram Handle</Form.Label>
                <Form.Control
                type="text" 
                placeholder="Handle"
                defaultValue={this.state.instagramHandle}
                onChange={e => this.setState({ instagramHandle: e.target.value })} 
                />
                </Col>
                <Col md>
                <Form.Label>Your Facebook Username</Form.Label>
                <Form.Control
                type="text" 
                placeholder="Username"
                defaultValue={this.state.facebookUsername}
                onChange={e => this.setState({ facebookUsername: e.target.value })} 
                />
                </Col>
              </Row>
              <Row>
                <Col md>
                <OverlayTrigger placement="top" overlay={<Tooltip>Review text that is displayed under the review section on the home page.</Tooltip>}>
                  <div>
                    <Form.Label>Homepage Footer Left</Form.Label>

                <Form.Control
                    as="select"
                    value={this.state.homepageIdLeft}
                  onChange={e => {
                    this.setState({ homepageIdLeft: e.target.value })
                  }}
                    >
                  <option value={null}>Select a page to show..</option>
                  {this.state.pages && this.state.pages.map(({ id, header_name }) => (

                      <option value={id}>{ header_name }</option>

                ))}
                </Form.Control>
                </div>
                </OverlayTrigger>
                </Col>
                <Col md>
                <OverlayTrigger placement="top" overlay={<Tooltip>Text displayed on the home page under the review on the home page, citing the reviewer.</Tooltip>}>
                  <div>
                 <Form.Label>Homepage Footer Center</Form.Label>
                <Form.Control
                    as="select"
                    defaultValue={this.state.homepageIdCenter}
                  onChange={e => {
                    this.setState({ homepageIdCenter: e.target.value })
                  }}
                  >
                    <option value={null}>Select a page to show..</option>
                  {this.state.pages && this.state.pages.map(({ id, header_name }) => (

                      <option value={id}>{ header_name }</option>

                ))}
                </Form.Control>
                </div>
                </OverlayTrigger>
                </Col>
            </Row>
                          <Row>
                <Col md>
                <Form.Label>Homepage Left Footer Display Name</Form.Label>
                <Form.Control 
                type="text" 
                placeholder="Homepage Left Footer Display Name"
                defaultValue={this.state.homepageLeftDisplayText}
                onChange={e => this.setState({ homepageLeftDisplayText: e.target.value })} 
                />
                </Col>
                <Col md>
                <Form.Label>Homepage Center Footer Display Name</Form.Label>
                <Form.Control
                type="text" 
                placeholder="Homepage Center Footer Display Name"
                defaultValue={this.state.homepageCenterDisplayText}
                onChange={e => this.setState({ homepageCenterDisplayText: e.target.value })} 
                />
                </Col>
              </Row>
            <div style={{padding: 20, marginTop: 20, marginBottom: 20}}
            >
              <h2>Features</h2>
            <Row style={{textAlign: 'left'}}>
                <Col md>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Enabling the shop will make the shop visible in the website menu, you can add all of your shop items before turning on the shop.</Tooltip>}>
                    <Form.Check type="checkbox" checked={this.state.shopEnabled} onChange={() => this.setState({ shopEnabled: !this.state.shopEnabled })} label="Shop enabled" />
                  </OverlayTrigger>
                </Col>
                {this.state.shopEnabled &&
                  <Col md>
                  <OverlayTrigger placement="top" overlay={<Tooltip>The email you want customers to see their order confirmation has come from.</Tooltip>}>
                    <Form.Group>
                <Form.Label>Shop From Address</Form.Label>
                <Form.Control
                type="text" 
                placeholder="Email"
                defaultValue={this.state.shopReplyEmail}
                onChange={e => this.setState({ shopReplyEmail: e.target.value })} 
                />
                </Form.Group>
                </OverlayTrigger>
                  </Col>}
                {this.state.shopEnabled && 
                  <Col md>
                  <OverlayTrigger placement="top" overlay={<Tooltip>The Email where you want all new order notifications to go to. (e.g. A customer has just purchased one of your items)</Tooltip>}>
                  <Form.Group>
                    <Form.Label>Shop New Order Address</Form.Label>
                <Form.Control
                type="text" 
                placeholder="Email"
                defaultValue={this.state.shopNotifyEmail}
                onChange={e => this.setState({ shopNotifyEmail: e.target.value })} 
                    />
                </Form.Group>
                </OverlayTrigger>
                  </Col>}
                </Row>
                </div>
            </Form>
            <Button style={{marginTop: 5}} onClick={this.submitForm.bind(this)} variant="primary">Submit</Button>
      </div>
    }
    </div>
    )
  }
}