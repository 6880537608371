
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Card } from "react-bootstrap";
import ModalComponent from "./exhibition.modal.component"
import ExhibitionHeader from "./exhibition.cards.header";

export default class ExhibitionCards extends Component {

  constructor(props) {
    super();
    this.state = {
      apiResults: {},
      isLoaded: false,
      productId: 0,
      displayModal: false,
      windowWidth: window.innerWidth,
      pageText: props.pageText,
      pageId: props.pageId
    };
    this.containerRef = React.createRef()
  }

  async componentDidMount() {
    this.getAllExhibtionsResults();
  }

  getAllExhibtionsResults() {
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ page_id: this.state.pageId, limit: this.props.limit ? this.props.limit : "ALL", homepage: this.props.homepage}),
      mode: 'cors' 
    };

    fetch(`${process.env.REACT_APP_API_URL}/exhibitions`, requestOptions)
      .then(response => response.json())
      .then(res => {
        res.sort((a, b) => b.year - a.year);
        this.setState({ apiResults: res })
        this.setState({ isLoaded: true })
      })
      .catch((error) => {
        console.log("there was an error")
      })
  }

  createProducts() {
    const chunk = (arr, chunkSize = 1, cache = []) => {
      const tmp = [...arr]
      if (chunkSize <= 0) return cache
      while (tmp.length) cache.push(tmp.splice(0, chunkSize))
      return cache
    }

    const productsChunks = chunk(this.state.apiResults, 100);

    const rows = productsChunks.map((productChunk, index) => {
      const productsCols = productChunk.map((product, index) => {
          return (
            <Card key={index} style={{ margin: 10, padding: 5, width: '18rem' }}>
            <Card.Img onClick={() => ( this.handleClick(product.id)) }  style={{height: 250, cursor: "pointer"}} variant="top" src={product.image_bin} />
            <Card.Body>
              <Card.Title>{product.name}</Card.Title>
              <Card.Subtitle>{product.year}</Card.Subtitle>
              <Card.Text>
                {product.description}
              </Card.Text>
              {this.props.showButton && 
              <Button onClick={() => ( this.handleClick(product.id)) } variant="outline-secondary">{this.props.buttonText ? this.props.buttonText : "View Exhibition"}</Button>
              }
            </Card.Body>
          </Card>
        );
      });
      return <Row key={index}>{productsCols}</Row>
  });
  return rows;
  }
  
  handleClick(id) {
    this.setState({productId: id})
    this.setState({displayModal: true})
  }

  handleModalClose() {
    this.setState({displayModal: false})
  }

  render() {
    return (
      <div>
      {this.state.isLoaded && (
        <div>
        {this.state.displayModal && <ModalComponent CloseHandler={() => this.handleModalClose()} ExhibitionId={this.state.productId}/>}
        <div>
        {this.state.pageText && this.state.pageText !== "" && (<div style={{borderStyle:"solid", padding:"10px", borderRadius:"3px", borderWidth:"1px", borderColor:"lightgray", paddingLeft: "20px", paddingRight: "20px", textAlign: "left", whiteSpace: "pre-line", fontSize: "12px"}}>
          {this.state.pageText}
        </div>)}
      <Container ref={this.containerRef}>
      {this.createProducts()}
      </Container>
      </div>
      </div>
      )}
      </div>
    )
  }
}