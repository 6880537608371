import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import EditorContainer from "../admin/editor.container.component";
var moment = require('moment-timezone')

export default class NewsEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      news: [],
      loaded: false,
      currentEditId: 0,
      editing: false,
      pageId: props.pageId,
      pageName: props.pageName
    };
    this.setCurrentEdit = this.setCurrentEdit.bind(this);
    this.getNews = this.getNews.bind(this);
    this.rerender = this.rerender.bind(this);
  }

  async componentDidMount() {
    this.getNews();
  }

  rerender() {
    this.setState({editing: false, currentEditId: 0})
    this.getNews()
  }

  getNews() {
    fetch(`${process.env.REACT_APP_API_URL}/news?page=${this.state.pageId}`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      this.setState({ news: res })
      this.setState({loaded: true})
    })
  }

  setCurrentEdit(id) {
    if(id === 0) {
      this.setState({editing: false})
    }
    else {
      this.setState({editing: true})
    }
    this.setState({currentEditId : id})
  }

  getFormattedDate(dateStr) {
    var date = moment(dateStr).format('YYYY-MM-DD HH:mm:ss');    
    var stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local().format('DD/MM/YY HH:mm');
  }

  render() {
    console.log(this.state.currentEditId)
    return (
      <div>
        {this.state.loaded && (
          <div style={{borderStyle: "groove", padding: 10, borderRadius: 10, marginTop: "50px"}}>
            <h2>Edit {this.state.pageName} Page</h2>
        <Dropdown style={{margin:"5px"}}>
          <Dropdown.Toggle style={{margin:"5px"}} variant="success" id="dropdown-basic">
            Edit existing entry
          </Dropdown.Toggle>
          <Button onClick={() => this.setCurrentEdit(0)}>New Entry</Button>

          <Dropdown.Menu>
               {this.state.news.map(({ id, description,timestamp }) => (
                <div>
                  <Dropdown.Item onClick={() => this.setCurrentEdit(id)}>{description} ({this.getFormattedDate(timestamp)})</Dropdown.Item>
                </div>
              ))}
          </Dropdown.Menu>
        </Dropdown>
            <EditorContainer id={this.state.currentEditId} pageId={this.state.pageId} renderEdit={this.state.editing} getEndpoint="newsById" submitEndpoint="news" deleteEndpoint="deleteNews" rerender={this.rerender}/>
        </div>
        )}
      </div>
    )
  }
}