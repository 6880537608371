import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { FormControl } from "react-bootstrap"
import { SimpleModal } from "../modal.simple.component";
var moment = require('moment-timezone')

export default class ContactEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      contact: [],
      loaded: false,
      currentEditId: 0,
      editing: false,
      title: '',
      description: '',
      email: '',
      mobile: '',
      address: '',
      instagram: '',
      website: '',
      image: null,
      fileToUpload: null,
      showModal: false,
      modalTitle: '',
      modalBody: ''
    };
    this.setCurrentEdit = this.setCurrentEdit.bind(this);
    this.getContact = this.getContact.bind(this);
    this.resetState = this.resetState.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this)
    this.removeImage = this.removeImage.bind(this)
  }

  async componentDidMount() {
    this.getContact();
  }

  getContact() {
    fetch(`${process.env.REACT_APP_API_URL}/contact`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      this.setState({ contact: res })
      this.setState({loaded: true})
    })
  }

  showModal(body) {
    if (this.state.showModal) {
      this.setState({ modalBody: this.state.modalBody + ', ' + body })
    }
    else {
      this.setState({ showModal: true, modalTitle: 'Alert', modalBody: body })
    }
  }

  modalClosedCallback() {
    this.setState({showModal: false, modalTitle: '', modalBody: ''})
  }

  resetState() {
    this.setState({title: '', description: '', email: '', mobile: '', address: '', instagram: '', website: '', editing: false})
  }

  mapEditingToState(editingId) {
    this.state.contact.map(({ id, title, description, email, mobile, instagramhandle, address, websiteurl, image }) => {
      if(editingId === id) {
        this.setState({title: title, description: description, email: email, mobile: mobile, address: address, instagram: instagramhandle, website: websiteurl, image: image})
      }
    })
  }

  updateExhibitionEntity(file) {
    if(this.state.editing) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: localStorage.getItem("token"), id: this.state.currentEditId, title: this.state.title, description: this.state.description, email: this.state.email, mobile: this.state.mobile, address: this.state.address, instagramhandle: this.state.instagram, websiteurl: this.state.website, image: file==null ? this.state.image : file })
      }
      fetch(`${process.env.REACT_APP_API_URL}/updateContact`, requestOptions).then(res => res.status === 200 ? this.showModal("Changes saved") : this.showModal("There was an issue")).catch(error => this.showModal(`There was an error ${error}`))
    }
    else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: localStorage.getItem("token"), title: this.state.title, description: this.state.description, email: this.state.email, mobile: this.state.mobile, address: this.state.address, instagramhandle: this.state.instagram, websiteurl: this.state.website, image: file==null ? this.state.image : file  })
      }
      fetch(`${process.env.REACT_APP_API_URL}/createContact`, requestOptions).then(res => res.status === 200 ? this.showModal("Changes saved") : this.showModal("There was an issue")).catch(error => this.showModal(`There was an error ${error}`))
    }
  }

  submitForm() {
    if(this.state.fileToUpload) {
      this.uploadImageCallBack(this.state.fileToUpload).then(result => {
        this.setState({image: result})
        this.updateExhibitionEntity(result)
      })
    }
    this.updateExhibitionEntity(null)
  }

  deleteEntry() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: localStorage.getItem("token"), id: this.state.currentEditId })
    }
    fetch(`${process.env.REACT_APP_API_URL}/deleteContact`, requestOptions).then(res => {
      if(res.status === 200) {
        this.resetState();
        this.getContact();
        this.showModal("Contact deleted")
      }
      else this.showModal("There was an issue")
    }).catch(error => this.showModal(`There was an error ${error}`))
  }

  setCurrentEdit(id) {
    if(id === 0) {
      this.resetState()
    }
    else {
      this.setState({editing: true})
    }
    this.setState({currentEditId : id})
    this.mapEditingToState(id)
  }

  removeImage() {
    this.setState({image: null, fileToUpload: null})
  }

  uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        console.log(file)
        const reader = new FileReader()
        reader.onload = ev => {
          console.log(`resolved stuff: ${resolve(ev.target.result)}`)
        }
        return reader.readAsDataURL(file)
      }).then(result => {
        console.log(`result: ${result}`)
        console.log(file)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: localStorage.getItem("token"), imageBinary: result })
        };
        return fetch(`${process.env.REACT_APP_API_URL}/upload`, requestOptions).catch(error => this.showModal("There was an error"))
      }).then(response => { return response.json() })
      .then(data => {
        console.log(data.url) 
        return data.url})
    }

  uploadFiles(e) {
    console.log(e.target.files)
    if(e.target.files.length > 0) {
      this.setState({ fileToUpload: e.target.files[0] })
    }
}

  render() {
    console.log(this.state.currentEditId)
    return (
      <div>
        <SimpleModal show={ this.state.showModal } title={this.state.modalTitle} body={this.state.modalBody} modalClosedCallback={this.modalClosedCallback.bind(this)}  />
        {this.state.loaded && (
          <div style={{borderStyle: "groove", padding: 10, borderRadius: 10, marginTop: "50px"}}>
            <h2>Edit Contact Page</h2>
        <Dropdown style={{margin:"5px"}}>
          <Dropdown.Toggle style={{margin:"5px"}} variant="success" id="dropdown-basic">
            Edit existing entry
          </Dropdown.Toggle>
          <Button onClick={() => this.setCurrentEdit(0)}>New Entry</Button>

          <Dropdown.Menu>
               {this.state.contact.map(({ id, title }) => (
                <div>
                  <Dropdown.Item onClick={() => this.setCurrentEdit(id)}>{title}</Dropdown.Item>
                </div>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        <div style={{textAlign: "left"}}>
        <p style={{marginTop: 0, marginBottom: 0}}>Title</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='text'
          value={this.state.title}
          onChange={e => this.setState({ title: e.target.value })}
          placeholder="Title"
        />

        <p style={{marginTop: 0, marginBottom: 0}}>Description</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='text'
          value={this.state.description}
          onChange={e => this.setState({ description: e.target.value })}
          placeholder="Description"
        />

        <p style={{marginTop: 0, marginBottom: 0}}>Email</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='text'
          value={this.state.email}
          onChange={e => this.setState({ email: e.target.value })}
          placeholder="Email"
        />

        <p style={{marginTop: 0, marginBottom: 0}}>Contact Number</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='text'
          value={this.state.mobile}
          onChange={e => this.setState({ mobile: e.target.value })}
          placeholder="Number"
        />

        <p style={{marginTop: 0, marginBottom: 0}}>Address</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='text'
          value={this.state.address}
          onChange={e => this.setState({ address: e.target.value })}
          placeholder="Address"
        />

        <p style={{marginTop: 0, marginBottom: 0}}>Instagram Handle</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='text'
          value={this.state.instagram}
          onChange={e => this.setState({ instagram: e.target.value })}
          placeholder="Instagram"
        />

        <p style={{marginTop: 0, marginBottom: 0}}>Website URL</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='text'
          value={this.state.website}
          onChange={e => this.setState({ website: e.target.value })}
          placeholder="URL"
        />
      {this.state.image && <p style={{ marginTop: 0, marginBottom: 0 }}>Current Image</p>}
      {this.state.image && <img src={this.state.image}/>}
        <div className="form-group" style={{textAlign: 'left'}}>
        {this.state.image && <Button variant="danger" onClick={this.removeImage}>Remove Image</Button>}
                  <p style={{marginTop: 0, marginBottom: 0}}>Image to upload</p>
                  <input type="file" className="form-control" onChange={this.uploadFiles}/>
        </div>
        </div>
        <Button style={{marginTop: 5, marginRight: 5}} onClick={this.submitForm.bind(this)} variant="primary">Submit</Button>
        {this.state.editing && <Button variant="danger" style={{marginTop: 5}} onClick={() => this.deleteEntry()}>Delete Entry</Button>}
        </div>
        )}
      </div>
    )
  }
}