import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, } from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from './components/contact.component';
import ExhibitionCards from './components/exhibition.cards.component';
import Login from './components/login.component'
import AdminContainer from './components/admin/admin.container'
import News from './components/news.component'
import Biography from './components/biography.component'
import Home from './Home'
import Shop from './components/shop/shop.component'
import PrivateRoute from './PrivateRoute'
import Cart from './components/shop/cart.component';
import StripeContainer from './components/billing/stripe.container';
import OrderConfirmation from './components/shop/order.confirmation.component';
import OrderTable from './components/admin/orders.table.component';

function App() {
  const [expanded, setExpanded] = useState(false);
  const [siteSettings, setSiteSettings] = useState(null)
  const [imagePageRoutes, setImagePageRoutes] = useState(null)
  const [newsPageRoutes, setNewsPageRoutes] = useState(null)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/siteDetails`)
    .then(response => response.json())
    .then(res => {
      setSiteSettings(res[0])
    })
  }, [])
  
    useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/pages`)
    .then(response => response.json())
      .then(res => {
        var newsPages = []
        var imagePages = []
        res.map(({ id, header_name, name, page_text, ordering, hide_link }) => {
          if (name === 'Posts') newsPages.push({ id: id, header_name: header_name, page_text: page_text, ordering: ordering, hide_link: hide_link })
          if (name === 'Images') imagePages.push({ id: id, header_name: header_name, page_text: page_text, ordering: ordering, hide_link: hide_link })
        })
        setImagePageRoutes(imagePages)
        setNewsPageRoutes(newsPages)
    })
  }, [])
  
  
  return (<Router>
    <div className="App">
      {siteSettings && (<div>
        <Navbar expanded={expanded} bg="light" expand="lg" sticky="top">
        <Container>
            <Navbar.Brand href="/">{siteSettings.first_name} {siteSettings.last_name}</Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link onClick={() => setExpanded(false)} className="nav-link" to={"/Biography"}>Biography</Link>
                {siteSettings.shop_enabled && <Link onClick={() => setExpanded(false)} className="nav-link" to={"/Shop"}>Shop</Link>}
                {imagePageRoutes && newsPageRoutes && imagePageRoutes.concat(newsPageRoutes).sort((a, b) => (a.ordering > b.ordering) ? 1 : ((b.ordering > a.ordering) ? -1 : 0)).map(({ id, header_name, hide_link }) => {
                  if (!hide_link) {
                    return <Link key={id} onClick={() => setExpanded(false)} className="nav-link" to={'/' + header_name.replace(/[^a-zA-Z]/g, "")}>{header_name}</Link>
                  }
              })}
                <Link onClick={() => setExpanded(false)} className="nav-link" to={"/contact"}>Contact {siteSettings.first_name}</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        <Switch>
          <Route path="/contact">
          <div className="body-container">
            <Contact />
            </div>
          </Route>
          {imagePageRoutes && imagePageRoutes.map(({id, header_name, page_text}) => {
            return (<Route key={id} path={ '/' + header_name.replace(/[^a-zA-Z]/g, "")}>
          <div className="body-container">
                <ExhibitionCards key={id} pageId={id} pageText={page_text}/>
            </div>
          </Route>)
          })}
          <Route path="/Biography">
          <div className="body-container">
            <Biography/>
            </div>
          </Route>
          {newsPageRoutes && newsPageRoutes.map(({id, header_name, page_text}) => {
            return (<Route key={id} path={ '/' + header_name.replace(/[^a-zA-Z]/g, "")}>
          <div className="body-container">
                <News key={id} page={id} pageText={page_text}/>
            </div>
          </Route>)
          })}
          {siteSettings.shop_enabled &&
            <Route path="/shop">
              <div className="body-container">
                <Shop />
              </div>
            </Route>}
          <Route path="/cart">
          <div style={{marginTop: "20px"}}>
            <Cart/>
            </div>
          </Route>
          <Route path="/checkout">
          <div style={{marginTop: "20px"}}>
            <StripeContainer/>
            </div>
          </Route>
        <Route path="/orderconfirmation/:orderKey" component={OrderConfirmation}/>
          <Route path="/login">
          <div className="body-container">
          <Login/>
            </div>
          </Route>
          <PrivateRoute path='/admin' component={AdminContainer} />
          <PrivateRoute path='/adminorders' component={OrderTable} />
          <Route path="/">
            <Home siteSettings={ siteSettings }/>
          </Route>
        </Switch></div>)}

    </div></Router>
  );
}

export default App