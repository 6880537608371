import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { Form, Button, Row, Col } from "react-bootstrap"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { SimpleModal } from "../modal.simple.component";
import Tooltip from "react-bootstrap/Tooltip";
import TextBoxEditor from "./textbox.editor.component";

export default class PageEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      pages: [],
      loaded: false,
      currentPageId: 0,
      editing: false,
      headerName: '',
      pageTypes: [],
      currentPageTypeId: null,
      showModal: false,
      modalTitle: '',
      modalBody: '',
      pageText: '',
      hideLink: false,
      ordering: null
    };
    this.setCurrentPageId = this.setCurrentPageId.bind(this);
    this.getPages = this.getPages.bind(this);
    this.rerender = this.rerender.bind(this);
  }

  async componentDidMount() {
    this.rerender()
  }

  rerender() {
    this.setState({editing: false, currentEditId: 0, headerName: '' })
    this.getPageTypes().then(this.getPages())
  }

  getPageTypes() {
    return fetch(`${process.env.REACT_APP_API_URL}/pageTypes`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      this.setState({ pageTypes: res, currentPageTypeId: res[0]?.id })
    })
  }

  submitForm() {
    if (this.state.headerName === '') {
      this.showModal('Page Name cannot be blank')
    }
    else if (!this.state.editing && this.state.pages.find(entity => entity.header_name?.toLowerCase() === this.state.headerName?.toLowerCase())) {
      this.showModal('Cannot Create Page. A Page with this name already exists')
    }
    else {
      this.state.editing ? this.updatePage() : this.createPage()
    }
  }

  updatePage() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: localStorage.getItem("token"), id: this.state.currentPageId, header_name: this.state.headerName, page_text: this.state.pageText, hide_link: this.state.hideLink, ordering: this.state.ordering || 100})
    }
    fetch(`${process.env.REACT_APP_API_URL}/updatePage`, requestOptions).then(res => res.status === 200 ? this.showModal("Page Updated") : this.showModal("There was an issue")).catch(error => this.showModal(`There was an error ${error}`))
  }

  createPage() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: localStorage.getItem("token"), page_type_id: this.state.currentPageTypeId, header_name: this.state.headerName, page_text: this.state.pageText, hide_link: this.state.hideLink, ordering: this.state.ordering | 100})
    }
    fetch(`${process.env.REACT_APP_API_URL}/createPage`, requestOptions).then(res => res.status === 200 ? this.showModal("Page Created") : this.showModal("There was an issue")).catch(error => this.showModal(`There was an error ${error}`)).then(() => this.rerender())
  }

  deletePage() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: localStorage.getItem("token"), id: this.state.currentPageId})
    }
    fetch(`${process.env.REACT_APP_API_URL}/deletePage`, requestOptions).then(res => res.status === 200 ? this.showModal("Page deleted") : this.showModal("There was an issue")).catch(error => this.showModal(`There was an error ${error}`)).then(() => this.rerender())
  }



  getPages() {
    fetch(`${process.env.REACT_APP_API_URL}/pages`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      this.setState({ pages: res })
      this.setState({loaded: true})
    })
  }

  setCurrentPageId(editId) {
    if(editId === 0) {
      this.setState({editing: false, headerName: '', pageText: '', hideLink: false, ordering: null})
    }
    else {
      this.state.pages.map(({ id, header_name, page_text, hide_link, ordering }) => {
          if(id === editId) {
            this.setState({ headerName: header_name, pageText: page_text, currentPageId: id, hideLink: hide_link, ordering: ordering || 100 })
          }
      })
      this.setState({editing: true})
    }
    this.setState({currentPageId : editId})
  }

  modalClosedCallback() {
    this.setState({ showModal: false, modalTitle: '', modalBody: '' })
    window.location.reload()
  }

    showModal(body) {
    if (this.state.showModal) {
      this.setState({ modalBody: this.state.modalBody + ', ' + body })
    }
    else {
      this.setState({ showModal: true, modalTitle: 'Alert', modalBody: body })
    }
  }

  render() {
    return (
      <div>
        <SimpleModal show={ this.state.showModal } title={this.state.modalTitle} body={this.state.modalBody} modalClosedCallback={this.modalClosedCallback.bind(this)}  />
        {this.state.loaded && (
          <div style={{borderStyle: "groove", padding: 10, borderRadius: 10}}>
            <h2>Edit Pages</h2>
            <p style={{color: "orange"}}>{!this.state.editing ? 'Adding new page' : 'Editing existing page'}</p>
        <Dropdown style={{margin:"5px"}}>
          <Dropdown.Toggle style={{margin:"5px"}} variant="success" id="dropdown-basic">
            Edit existing page
          </Dropdown.Toggle>
          <Button onClick={() => this.setCurrentPageId(0)}>New Page</Button>

          <Dropdown.Menu>
               {this.state.pages.map(({ id, header_name }) => (
                <div>
                  <Dropdown.Item onClick={() => this.setCurrentPageId(id)}>{header_name}</Dropdown.Item>
                </div>
              ))}
          </Dropdown.Menu>
        </Dropdown>
            <Form>
              <Row>
                <Col md>
                <Form.Label>Page Name</Form.Label>
                <OverlayTrigger placement="top" overlay={<Tooltip>This will be used for the name of the page and URL.</Tooltip>}>
                <Form.Control 
                type="text" 
                placeholder="Page Name"
                defaultValue={this.state.headerName}
                onChange={e => this.setState({ headerName: e.target.value })} 
                />
                </OverlayTrigger>
                </Col>
              {!this.state.editing && (<Col md>
                <Form.Label>Page Type</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => {
                    this.setState({ currentPageTypeId: e.target.value })
                  }}
                >
                  {this.state.pageTypes.map(({ id, name }) => (

                      <option value={id}>{ name }</option>

                ))}
                </Form.Control>
              </Col>)}
              </Row>
              <Row style={{marginTop: 10}}>
              <OverlayTrigger placement="top" overlay={<Tooltip>This text will be displayed at the top of the selected page.</Tooltip>}>
                <Col md>
                  <Form.Label>Page Header Text</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    defaultValue={this.state.pageText}
                    onChange={e => this.setState({ pageText: e.target.value })}
                  />
                </Col>
              </OverlayTrigger>
              </Row>
              <Row style={{marginTop: 15, marginBottom: 15}}>
                <Col md>
                      <Form.Control
                        type='number'
                        value={this.state.ordering}
                        onChange={e => {
                          this.setState({ordering: e.target.value})
                          }}
                        placeholder="Link order"
                        />
                  </Col>
                <Col md>
                <Form.Check style={{textAlign: 'left'}} type="checkbox" checked={this.state.hideLink} onChange={(e) => this.setState({ hideLink: !this.state.hideLink})} label="Hide link from toolbar" />
              </Col>
              </Row>

            </Form>
            <div style={{ marginTop: 10 }} > 
              <Button style={{ marginRight: 10 }} onClick={this.submitForm.bind(this)} variant="primary">{!this.state.editing ? 'Create New Page' : 'Update Page'}</Button>
              {this.state.editing && <Button variant="danger" onClick={() => this.deletePage()}>Delete Page</Button>}
            </div>
                
        </div>
        )}
      </div>
    )
  }
}