import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import MultipleImageUploadComponent from "../image.upload.component";
import { FormControl, FormCheck } from "react-bootstrap"
import Loader from "react-loader-spinner";
import { Thumbs } from "react-responsive-carousel";
import { SimpleModal } from "../modal.simple.component";
var moment = require('moment-timezone')

export default class ExhibitionEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      exhibitions: [],
      loaded: false,
      currentEditId: 0,
      editing: false,
      exhibitionName: '',
      exhibitionDescription: '',
      exhibitionYear: '',
      imagesEditing: [],
      imagesToUpload: [],
      showLoader: false,
      showModal: false,
      modalTitle: '',
      modalBody: '',
      pageId: props.pageId,
      isHomepage: false
    };
    this.setCurrentEdit = this.setCurrentEdit.bind(this);
    this.imageUpload = this.imageUpload.bind(this);
  }

  async componentDidMount() {
    this.getExhibitions();
  }

  getExhibtion(id) {
    console.log()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: id }),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/exhibition`, requestOptions)
      .then(response => response.json())
      .then(res => {
        var images = []
        res.map(({image_bin, image_name, image_description, ordering, id}) => {
          images.push({image: image_bin, id: id, image_name: image_name, image_description: image_description, ordering: ordering, changed: false})
        })
        this.setState({imagesEditing: images})
        this.setState({editing: true})
        console.log(this.state.imagesEditing)
      })
      .catch((error) => {
        console.log("there was an something")
      })
  }

  getExhibitions() {
    const exhibitionRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ page_id: this.state.pageId })
    }

    fetch(`${process.env.REACT_APP_API_URL}/exhibitions`, exhibitionRequestOptions)
    .then(response => response.json())
    .then(res => {
      this.setState({ exhibitions: res })
      this.setState({loaded: true})
    })
  }

  imageUpload(files) {
    console.log(files)
    this.setState({imagesToUpload: files})
  }

  uploadImageCallBack(order, image_name, image_description, url, exhibitionId) {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ev => {
          resolve(ev.target.result)
        }
        return reader.readAsDataURL(url)
      }).then(result => {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: localStorage.getItem("token"), imageBinary: result, is_default: false, exhibition_id: exhibitionId, image_name: image_name, image_description: image_description, order: order })
        };
        return fetch(`${process.env.REACT_APP_API_URL}/upload`, requestOptions).catch(error => this.showModal("There was an error"))
      }).then(response => { return response.json() })
      .then(data => { 
        return { data: { link: data.url }} })
  }

  deleteImage(id) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({token: localStorage.getItem("token"), id: id}),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/deleteExhibitionImage`, requestOptions)
      .then(response => {
        if(response.status === 200) {
          var tempArray = []
          this.state.imagesEditing.forEach(image => {
            if(image.id !== id) {
              tempArray.push(image);
            }
          })
          this.setState({imagesEditing: tempArray})
          this.showModal("Entry deleted successfully")
        }
        else {
          this.showModal("There was an issue");
        }
      })
      .catch((error) => {
        this.showModal("There was an error")
        console.log(error)
      })
  }

  deleteEntry() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({token: localStorage.getItem("token"), id: this.state.currentEditId }),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/deleteExhibition`, requestOptions)
      .then(response => {
        if(response.status === 200) {
          var tempArray = []
          this.state.exhibitions.forEach(exhibition => {
            if(exhibition.id !== this.state.currentEditId) {
              tempArray.push(exhibition);
            }
          })
          this.setState({exhibitions: tempArray})
          this.setCurrentEdit(0)
          this.showModal("Entry deleted successfully")
        }
        else {
          this.showModal("There was an issue");
        }
      })
      .catch((error) => {
        this.showModal("There was an error")
        console.log(error)
      })
  }

  submitForm() {
    this.setState({showLoader: true})
    if(this.state.editing) {
      var updateArray = []
      var promisesArray = []
      var errors = []
      this.state.imagesEditing.map(({ id, image_name, image_description, ordering, changed }) => {
        if(changed) {
          updateArray.push({id: id, image_name: image_name, image_description: image_description, ordering: ordering})
        }
      } )
      if(updateArray.length > 0) {
      const imagesRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'token': localStorage.getItem("token")},
        body: JSON.stringify(updateArray)
      };
      promisesArray.push(fetch(`${process.env.REACT_APP_API_URL}/updateExhibitionImages`, imagesRequestOptions)
      .then(res => res.json()).then(e => errors.push(e)))
      }
      
      const exhibitionRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token: localStorage.getItem("token"), name: this.state.exhibitionName, description: this.state.exhibitionDescription, year: this.state.exhibitionYear, id: this.state.currentEditId, isHomepage: this.state.isHomepage })
      }
      promisesArray.push(fetch(`${process.env.REACT_APP_API_URL}/updateExhibition`, exhibitionRequestOptions)
      .then(res => res.json()).catch(e => errors.push(e)))

      this.state.imagesToUpload.map(({order, image_name, image_description, url }) => { 
        promisesArray.push(this.uploadImageCallBack(order, image_name, image_description, url, this.state.currentEditId).catch(e => errors.push(e)))
       })
       Promise.all(promisesArray).then(() => { 
         this.setState({showLoader: false})
         this.showModal("Upload complete")
        })
    }
    else {
      var promises = []
      //if no images provided this.showModal some message
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: localStorage.getItem("token"), name: this.state.exhibitionName, year: this.state.exhibitionYear, description: this.state.exhibitionDescription, pageId: this.props.pageId, isHomepage: this.state.isHomepage })
      };
      fetch(`${process.env.REACT_APP_API_URL}/createExhibition`, requestOptions)
      .then(res => res.json())
      .then(response => {
        console.log("STATE OF IMAGES AFTER RESPONSE FROM CREATE EXHIBITION")
        console.log(this.state.imagesToUpload)
        this.state.imagesToUpload.map(({order, image_name, image_description, url }) => { 
          console.log(response)
          promises.push(this.uploadImageCallBack(order, image_name, image_description, url, response.id))
          console.log(promises)
         })
         Promise.all(promises).then(() => {
           this.getExhibtion(response.id)
           this.setState({showLoader: false })
           this.showModal("Upload complete")
          })
      })
      .catch(error => this.showModal(error))
    }
  }

  showModal(body) {
    if (this.state.showModal) {
      this.setState({ modalBody: this.state.modalBody + ', ' + body })
    }
    else {
      this.setState({ showModal: true, modalTitle: 'Alert', modalBody: body })
    }
  }

  modalClosedCallback() {
    this.setState({showModal: false, modalTitle: '', modalBody: ''})
  }

  setCurrentEdit(editId) {
    console.log(editId)
    if(editId === 0) {
      console.log(this.state.exhibitions)
      this.setState({editing: false, exhibitionName: '', exhibitionDescription: '', exhibitionYear: '', isHomepage: false})
    }
    else {
      console.log(this.state.exhibitions)
      this.state.exhibitions.map(({id, name, description, year, is_homepage }) => {
        if (id === editId) {
            console.log('is it homepage ' + is_homepage)
            this.setState({exhibitionName: name, exhibitionDescription: description, exhibitionYear: year, isHomepage: is_homepage})
            this.getExhibtion(id)
          }
      })
    }
    this.setState({currentEditId : editId})
  }

  render() {
    return (
      <div>
        <SimpleModal show={ this.state.showModal } title={this.state.modalTitle} body={this.state.modalBody} modalClosedCallback={this.modalClosedCallback.bind(this)}  />
        {this.state.loaded && (
          <div style={{borderStyle: "groove", padding: 10, borderRadius: 10, marginTop: "50px"}}>
            <h2>Edit {this.props.pageName ? this.props.pageName : "Exhibtion"} Page</h2>
        <Dropdown style={{margin:"5px"}}>
          <Dropdown.Toggle style={{margin:"5px"}} variant="success" id="dropdown-basic">
            Edit existing entry
          </Dropdown.Toggle>
          <Button onClick={() => this.setCurrentEdit(0)}>New Entry</Button>

          <Dropdown.Menu>
               {this.state.exhibitions.map(({ id, name, year }) => (
                <div>
                  <Dropdown.Item onClick={() => this.setCurrentEdit(id)}>{name} ({year})</Dropdown.Item>
                </div>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        <div  style={{textAlign: "left"}}>
        <p style={{marginTop: 0, marginBottom: 0}}>{this.props.pageName ? this.props.pageName : "Exhibtion"}  Name</p>
        <FormControl
          style={{maxWidth: 300}} 
          type='text'
          value={this.state.exhibitionName}
          onChange={e => this.setState({ exhibitionName: e.target.value })}
          placeholder="Name"
        />
        <p style={{marginTop: 0, marginBottom: 0}}>{this.props.pageName ? this.props.pageName : "Exhibtion"}  Description</p>
        <FormControl
          style={{maxWidth: 300}} 
          type='text'
          value={this.state.exhibitionDescription}
          onChange={e => this.setState({ exhibitionDescription: e.target.value })}
          placeholder="Description"
        />
        <p style={{marginTop: 0, marginBottom: 0}}>{this.props.pageName ? this.props.pageName : "Exhibtion"}  Year</p>
        <FormControl
          style={{maxWidth: 300, marginBottom: 10}} 
          type='number'
          value={this.state.exhibitionYear}
          onChange={e => this.setState({ exhibitionYear: e.target.value })}
          placeholder="Year"
              />
        <FormCheck type="checkbox" checked={this.state.isHomepage} onChange={(e) => this.setState({ isHomepage: !this.state.isHomepage})} label="Display on Homepage Carousel" />
        </div>
        {!this.state.editing && <MultipleImageUploadComponent imageCallback={this.imageUpload} />}
        {this.state.editing && (
          <div style={{textAlign: 'left'}}>
            <MultipleImageUploadComponent imageCallback={this.imageUpload} />
            <h2>Existing Images</h2>
              {this.state.imagesEditing.map(({ image, id, image_name, image_description, ordering }) => (
                <div style={{margin: 5, marginBottom: 20}}>
                    <img style={{maxWidth: 300}} src={image} alt="loading"/>
                    <p style={{marginTop: 0, marginBottom: 0}}>Order</p>
                        <FormControl
                        style={{maxWidth: 300}} 
                        type='number'
                        value={ordering}
                        onChange={e => {
                          var temp = this.state.imagesEditing
                          var tempEntity = temp.find(entity => entity.id === id);
                          tempEntity.ordering = e.target.value;
                          tempEntity.changed = true;
                          this.setState({imagesEditing : temp})
                          }}
                        placeholder="Image order number"
                        />
                        <p style={{marginTop: 0, marginBottom: 0}}>Image Name</p>
                        <FormControl
                        style={{maxWidth: 300}} 
                        type='text'
                        value={image_name}
                        onChange={e => {      
                          var temp = this.state.imagesEditing
                          var tempEntity = temp.find(entity => entity.id === id);
                          tempEntity.image_name = e.target.value;
                          tempEntity.changed = true;
                          this.setState({imagesEditing : temp})
                        }}
                        placeholder="Name"
                        />
                        <p style={{marginTop: 0, marginBottom: 0}}>Image Description</p>
                        <FormControl
                        style={{maxWidth: 300}} 
                        type='text'
                        value={image_description}
                        onChange={e => {      
                          var temp = this.state.imagesEditing
                          var tempEntity = temp.find(entity => entity.id === id);
                          tempEntity.image_description = e.target.value;
                          tempEntity.changed = true;
                          this.setState({imagesEditing : temp})
                        }}
                        placeholder="Description"
                        />
                        {this.state.editing && <Button variant="danger" style={{marginTop: 5}} onClick={() => this.deleteImage(id)}>Remove Image</Button>}
                </div>
              ))}
          </div>
        )}
            {this.state.showLoader &&       
      <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible={true}
      /> }
        {!this.state.showLoader && <Button style={{marginTop: 5}} onClick={() => this.submitForm()}>Submit</Button>}
        {this.state.editing && !this.state.showLoader && <Button variant="danger" style={{marginTop: 5, marginLeft: 5}} onClick={() => this.deleteEntry()}>Delete</Button>}
        </div>
        )}
      </div>
    )
  }
}