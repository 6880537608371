import React, {Component} from 'react';
import {convertToRaw, EditorState, ContentState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "../../css/editor.container.css"
import { Button } from "react-bootstrap"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FormControl } from "react-bootstrap"
import Loader from "react-loader-spinner";
import { SimpleModal } from '../modal.simple.component';

export default class EditorContainer extends Component{
  constructor(props){
    super(props);
    this.state = {
      editorState: '',
      editing: false,
      editingId: 0,
      isLoaded: false,
      getEndpoint: props.getEndpoint,
      submitEndpoint: props.submitEndpoint,
      deleteEndpoint: props.deleteEndpoint,
      editingDescription: '',
      showLoader: false,
      hideDescription: props.hideDescription,
      showModal: false,
      modalTitle: '',
      modalBody: '',
      pageId: props.pageId
    };
    this.textInput = React.createRef(); 
  }

  componentWillReceiveProps(nextProps) {
    console.log("props changed")
    this.rerenderEditorState(nextProps)
    console.log(this.props)
    console.log(nextProps)
  }

  async componentDidMount() {
    console.log("did mount")
    this.rerenderEditorState(this.props)
  }

  rerenderEditorState(props) {
    if(props.id && props.renderEdit) {
      fetch(`${process.env.REACT_APP_API_URL}/${this.state.getEndpoint}`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({id : props.id })
      })
      .then(response => response.json())
      .then(res => {
        const contentBlock = htmlToDraft("<p>" + res[0].html + "</p>");
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({editorState: editorState});
        this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.setState({editingDescription: res[0].description})
        this.textInput.current = res[0].description
        this.setState({editing: true})
        this.setState({editingId: props.id})
        console.log("state update finished")
        console.log("editing id " + this.state.editingId)
      })
    }
    else {
      console.log("Empty")
      this.setState({editorState: EditorState.createEmpty()})
      this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
      this.onEditorStateChange = this.onEditorStateChange.bind(this);
      this.setState({editing: false})
    }
  }
  

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  parentRerender() {
    if (this.props.rerender) {
      this.props.rerender();
    }
  }

  deleteEntry() {
    console.log("delete called")
    if(this.state.editing && this.state.deleteEndpoint) {
      this.setState({showLoader: true});
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: localStorage.getItem("token"), id: this.state.editingId })
      };
      fetch(`${process.env.REACT_APP_API_URL}/${this.state.deleteEndpoint}`, requestOptions)
      .then(response => {
        if(response.status === 200) {
          this.showModal("Successfully Deleted Entry")
          this.parentRerender();
        }
        else {
          this.showModal("There was an error deleting the entry")
        }
        this.setState({showLoader: false, editing: false, editingDescription: ''})
      })
      .catch(error => {
        this.showModal(`There was an error ${error}`);
        this.setState({showLoader: false});
      })
    }
  }

  submitEditor() {
    this.setState({showLoader: true})
    if(!this.state.hideDescription && this.state.editingDescription === "") {
      this.showModal("Post identifier cannot be empty")
      this.setState({showLoader: false})
    }
    else {
      var htmlContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    console.log(htmlContent);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: this.state.editing ? JSON.stringify({ token: localStorage.getItem("token"), id: this.state.editingId, html: htmlContent, description: this.state.editingDescription, pageId: this.state.pageId }) : JSON.stringify({ token: localStorage.getItem("token"), html: htmlContent, description: this.state.editingDescription, pageId: this.state.pageId })
    };
    fetch(`${process.env.REACT_APP_API_URL}/${this.state.submitEndpoint}`, requestOptions)
    .then(response => {
      if(response.status === 200) {
        this.showModal("Upload completed")
        this.parentRerender();
      }
      else {
        this.showModal("There was an error updating the entry")
      }
      this.setState({showLoader: false})
    })
    .catch(error => this.showModal(`There was an error ${error}`))
  }
  }

  uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        console.log(file)
        const reader = new FileReader()
        reader.onload = ev => {
          console.log(`resolved stuff: ${resolve(ev.target.result)}`)
        }
        return reader.readAsDataURL(file)
      }).then(result => {
        console.log(`result: ${result}`)
        console.log(file)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: localStorage.getItem("token"), imageBinary: result })
        };
        return fetch(`${process.env.REACT_APP_API_URL}/upload`, requestOptions).catch(error => this.showModal("There was an error"))
      }).then(response => { return response.json() })
      .then(data => {
        console.log(data.url) 
        return { data: { link: data.url }} })
    }

    handleChange() {
      console.log(this.textInput.current)
      this.setState({editingDescription: this.textInput.current.value})
    }
  
    showModal(body) {
    if (this.state.showModal) {
      this.setState({ modalBody: this.state.modalBody + ', ' + body })
    }
    else {
      this.setState({ showModal: true, modalTitle: 'Alert', modalBody: body })
    }
  }

  modalClosedCallback() {
    this.setState({showModal: false, modalTitle: '', modalBody: ''})
  }


  render() {
    const { editorState } = this.state;
    return  (
      <div>
        <SimpleModal show={ this.state.showModal } title={this.state.modalTitle} body={this.state.modalBody} modalClosedCallback={this.modalClosedCallback.bind(this)}  />
        {!this.props.hideDescription && !this.state.showLoader &&(
        <FormControl
        style={{marginBottom: 5}} 
        type='text'
        value={this.state.editingDescription}
        onChange={e => this.setState({ editingDescription: e.target.value })}
        placeholder="Enter a name used to identify this post internally"
      />)}
      {!this.state.showLoader &&
    <div className='editor'>
       <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/react-draft-wysiwyg@1.12.3/dist/react-draft-wysiwyg.css' />
      <Editor
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: this.uploadImageCallBack, previewImage: false, alt: { present: true, mandatory: true } },
        }}
      />
    </div>}
    {this.state.showLoader &&       
    <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible={true}
      /> }
      {!this.state.showLoader && <Button style={{marginTop: 5, marginRight: 5}} onClick={this.submitEditor.bind(this)} variant="primary">Submit</Button>}
      {this.state.editing && !this.state.showLoader && <Button variant="danger" style={{marginTop: 5}} onClick={() => this.deleteEntry()}>Delete Entry</Button>}
    </div>
    )
  }
}