import React, { Component } from "react";

export default class Biography extends Component {
  constructor(props) {
    super();
    this.state = {
      html: [],
      loaded: false
    };
    this.getRawHtml();
  }

  getRawHtml() {
    fetch(`${process.env.REACT_APP_API_URL}/biography`, { mode: 'cors' })
    .then(response => response.json())
    .then(res => {
      this.setState({ html: res })
      this.setState({loaded: true})
    })
  }

  render() {
    return (
      <div className="container" style={{textAlign: "left", paddingBottom: "50px"}}>
        {this.state.loaded && this.state.html.map(({ html }) => (
          <div className="post" style={{overflowWrap: "break-word"}}>
                <div dangerouslySetInnerHTML={{__html: html}}>
                </div>
                </div>
              ))
    }
      </div>
    )
  }
}