import React, {Component} from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./checkout.form";

const PUBLIC_KEY = "pk_live_51MncU0E3sFbWusPPr76k8muLMgo41wGsXAjdCj3ELYS2IjZpqSONXMwjGH4fGdHSu6aqgUmVoJAiYgRWqb0VOJYN00cLCgVB1I";

const stripeTestPromise = loadStripe(process.env.STRIPE_API_KEY || PUBLIC_KEY);

export default class Stripe extends Component {
  constructor(props) {
    super();
    this.state ={
      loaded: false,
      amount: null
    }
  }

  componentDidMount() {
    this.setState({amount: this.props.amount, loaded: true})
  }

  render() {
  return (
    <Elements stripe={stripeTestPromise}>
      {this.state.loaded && <CheckoutForm/>}
    </Elements>
  );
  }
}