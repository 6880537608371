import React from "react";
import { Modal, Button } from 'react-bootstrap'

export const SimpleModal = (props) => {
  return (
    <Modal centered={true} enforceFocus={false} show={props.show} onHide={props.modalClosedCallback}>
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign: 'center'}}>
        {props.body}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.modalClosedCallback}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>)
}
