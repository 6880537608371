import React, {Component} from "react";
import { Button } from 'react-bootstrap'

export default class OrderConfirmation extends Component {
  constructor(props) {
    super();
    this.state = {
      orderKey: null,
      orderLoaded: false,
      orders: null,
      orderDetails: null
    }
  }

  componentDidMount() {
    this.getOrderDetails(this.props.match.params.orderKey)
    localStorage.setItem('cart', '{}')
  }

  getOrderDetails(orderKey) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ order_key: orderKey }),
      mode: 'cors' 
    };
    fetch(`${process.env.REACT_APP_API_URL}/getOrder`, requestOptions)
    .then(response => response.json())
    .then(res => {
      this.setState({ orders: res, orderKey: orderKey, orderLoaded: true, orderDetails: res[0] }, () => {
        console.log(res[0])
    })
    })
    .catch((error) => {
      console.log("there was an error")
      console.log(error)
    })
  }


  render() {
    return (
      <div class="body-container">
        {this.state.orderLoaded && this.state.orderDetails && (
          <div>
            <img src='/orderconfirmed.png' style={{width: '15vw', maxWidth: '230px', marginBottom: 20}}/>
            <h2>Hello {this.state.orderDetails.first_name}, Thank You For Your Order</h2>
            <span style={{display: 'block', marginBottom: 20 }}>You will receive an email shortly confirming your order.</span>
            <div style={{ border: 'solid', borderWidth: '0.5px', maxWidth: '80vw', minWidth: '3 0vw', borderRadius: '4px', display: 'inline-block', padding: 10}}>
              <span style={{ display: 'block', fontSize: '22px' }}>Delivery Details</span>
              <span style={{ display: 'block' }}>Full Name: {this.state.orderDetails.first_name} {this.state.orderDetails.last_name}</span>
              <span style={{ display: 'block' }}>Contact Number: {this.state.orderDetails.phone}</span>
              <span style={{ display: 'block' }}>Street Address: {this.state.orderDetails.street_address}</span>
              <span style={{ display: 'block' }}>City: {this.state.orderDetails.city}</span>
            </div>
          </div>
        )}
        <div style={{marginTop: '10px', marginBottom: '10px'}}>
          <Button onClick={() => window.location.pathname = '/shop'} variant="outline-primary">Return To Shop</Button>
        </div>
          </div>
  );
}
}