import React, { useEffect, useState } from "react";
import { Table, Button } from 'react-bootstrap'

function OrderTable() {
  const [orders, setOrders] = useState(null)

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/getOrders`, { headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem("token") }})
    .then(response => response.json())
      .then(res => {
        setOrders(res)
        console.log(res)
    })
  }, [])

  return orders && (
    <div className="body-container">
      <Button variant="secondary" onClick={() => window.location.pathname = "/admin"} style={{marginBottom: 20}}>Back to Admin Portal</Button>
      <Table striped bordered hover>
    <thead>
          <tr>
            <th>id</th>
            <th>State</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Address</th>
            <th>Item</th>
            <th>Item Size</th>
            <th>Quantity</th>
            <th>Item Shipping Cost</th>
            <th>Item Value Total</th>
            <th>Order Total</th>
            <th>Order Created At</th>
            <th>Stripe Payment Intent</th>
            <th>Error Message</th>
    </tr>
  </thead>
  <tbody>
  {orders.map(({ order_id, state, first_name, last_name, email, street_address, title, quantity, shipping_cost, amount_charged_each, total_value, item_types, item_type_id, timestamp, payment_intent_id, error_message }) => {
    const itemTypes = JSON.parse(item_types)
    const itemType = itemTypes.find(type => type.id == item_type_id)
    return (
      <tr>
        <td>{order_id}</td>
        <td>{state}</td>
      <td>{ first_name }</td>
      <td>{ last_name }</td>
        <td>{email}</td>
        <td>{street_address}</td>
        <td>{title}</td>
        <td>{itemType?.size}</td>
        <td>{quantity}</td>
        <td>${(shipping_cost * quantity) / 100}</td>
        <td>${(amount_charged_each * quantity) / 100}</td>
        <td>${(total_value) / 100}</td>
        <td>{timestamp && new Date(timestamp).toLocaleString()}</td>
        <td>{payment_intent_id}</td>
        <td>{error_message}</td>
    </tr>
      )
    })}
  </tbody>
</Table>
      </div>
    )
}

export default OrderTable