import React from "react"
import Collection from "./components/accordion.image.component"
import { Container } from "react-bootstrap";
import ExhibitionCards from "./components/exhibition.cards.component";

export default class Home  extends React.Component  {
  constructor(props) {
    super();
    this.state = {
      apiResults: {},
      news: {},
      press: {},
      isLoaded: false,
      newsLoaded: false,
      pressLoaded: false,
      siteSettings: props.siteSettings,
      footerLeft: null,
      footerCenter: null
      }
    };

    async componentDidMount() {
      this.getExhibtionsResults();
      this.getFooterCenter();
      this.getFooterLeft();
    }
  
    getFooterLeft() {
      if (!this.state.siteSettings.footer_left_page_id) return
      fetch(`${process.env.REACT_APP_API_URL}/news?page=${this.state.siteSettings.footer_left_page_id}`, { mode: 'cors' })
      .then(function(response) {
        if(response.ok)
        {
          return response.json();         
        }
  
        throw new Error('Something went wrong.');
      })  
      .then(res => {
        this.setState({ footerLeft: res })
      })
      .catch(error => console.log(error))
    }
  
    getFooterCenter() {
      if (!this.state.siteSettings.footer_center_page_id) return
      fetch(`${process.env.REACT_APP_API_URL}/news?page=${this.state.siteSettings.footer_center_page_id}`, { mode: 'cors' })
      .then(function(response) {
        if(response.ok)
        {
          return response.json();         
        }
  
        throw new Error('Something went wrong.');
      })  
      .then(res => {
        this.setState({ footerCenter: res })
      })
      .catch(error => console.log(error))
    }
  
    getNews() {
      fetch(`${process.env.REACT_APP_API_URL}/news?page=2`, { mode: 'cors' })
      .then(function(response) {
        if(response.ok)
        {
          return response.json();         
        }
  
        throw new Error('Something went wrong.');
      })  
      .then(res => {
        this.setState({ news: res })
        this.setState({newsLoaded: true})
      })
      .catch(error => console.log(error))
    }

    getPress() {
      fetch(`${process.env.REACT_APP_API_URL}/press`, { mode: 'cors' })
      .then(function(response) {
        if(response.ok)
        {
          return response.json();         
        }
  
        throw new Error('Something went wrong.');
      })  
      .then(res => {
        this.setState({ press: res })
        this.setState({pressLoaded: true})
      })
      .catch(error => console.log(error))
    }

  getExhibtionsResults() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ homepage: true, limit: 6 }),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/exhibitions`, requestOptions)
    .then(function(response) {
      if(response.ok)
      {
        return response.json();         
      }

      throw new Error('Something went wrong.');
    })  
      .then(res => {
        this.setState({ apiResults: res })
        this.setState({ isLoaded: true })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  formatDate = (dateString) => {
    const options = { month: "short", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  render() {
    return (
      <div>
      { this.state.isLoaded && <Collection images={this.state.apiResults}/> }
      <Container>
        <div style={{textAlign: "left"}} id="MobileCards" >
        <div style={{display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: '20px', textAlign: 'left', marginLeft: "5%"}}>
        <div style={{marginLeft: "5%", width: "250px"}}>
        <h2 style={{fontWeight: "200", fontSize: "2em"}}>Latest Exhibition</h2>
          <hr style={{display: "block", height: "1px", border: 0, borderTop: "1px solid #000000", margin: "1em 0", padding: 0}}/>
        </div>
        <ExhibitionCards homepage={true} limit={1} showButton/>
        </div>
        </div>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: '20px', textAlign: 'left', marginLeft: "5%" }}>
            {this.state.siteSettings && this.state.siteSettings.footer_left_page_id &&
              <div className="home info" style={{ marginLeft: "5%", width: "250px" }}>
                <h2 style={{ fontWeight: "200", fontSize: "2em" }}>{this.state.siteSettings.footer_left_display_name}</h2>
                <hr style={{ display: "block", height: "1px", border: 0, borderTop: "1px solid #000000", margin: "1em 0", padding: 0 }} />
                {this.state.footerLeft && this.state.footerLeft.map(({ timestamp, description }) => (
                  <div className="post">
                    <span>{this.formatDate(timestamp)}: </span>
                    <span>{description}</span>
                  </div>
                ))
                }
                <a style={{ background: "url(/NextArrow.png) no-repeat scroll right transparent", paddingRight: "20px" }} href="/news">View all {this.state.siteSettings && this.state.siteSettings.footer_left_display_name}</a>
              </div>}
            {this.state.siteSettings && this.state.siteSettings.footer_center_page_id &&
              <div className="home info" style={{ marginLeft: "5%", width: "250px" }}>
                <h2 style={{ fontWeight: "200", fontSize: "2em" }}>{this.state.siteSettings.footer_center_display_name}</h2>
                <hr style={{ display: "block", height: "1px", border: 0, borderTop: "1px solid #000000", margin: "1em 0", padding: 0 }} />
                {this.state.footerCenter && this.state.footerCenter.map(({ timestamp, description }) => (
                  <div className="post">
                    <span>{this.formatDate(timestamp)}: </span>
                    <span>{description}</span>
                  </div>
                ))
                }
                <a style={{ background: "url(/NextArrow.png) no-repeat scroll right transparent", paddingRight: "20px" }} href="/press">View all {this.state.siteSettings && this.state.siteSettings.footer_center_display_name}</a>
              </div>}
        <div className="home info" style={{marginLeft: "5%", width: "250px"}}>
        <h2 style={{fontWeight: "200", fontSize: "2em"}}>Subscribe</h2>
        <hr style={{display: "block", height: "1px", border: 0, borderTop: "1px solid #000000", margin: "1em 0", padding: 0}}/>
          <div style={{display: "flex"}}>
            <img style={{maxHeight: "20px", marginTop: "2px"}} src="/instagram.png"></img><p style={{marginLeft: 5}}>Follow on</p><a style={{marginLeft:"3px"}} target="_blank" href={`https://instagram.com/${this.state.siteSettings.instagram_handle}`}>Instagram</a>
          </div>
          <div style={{display: "-webkit-inline-box"}}> 
          <img style={{maxHeight: "20px", marginTop: "2px"}} src="/facebook.png"></img><p style={{marginLeft: 5}}>Join </p><a style={{marginLeft:"3px"}} target="_blank" href={`https://facebook.com/${this.state.siteSettings.facebook_username}`}>Facebook Page</a>
          </div>
          <div style={{display: "flex"}}><img style={{maxHeight: "20px", marginTop: "2px"}} src="/contact.png"></img><p style={{marginLeft: 5}}>Get in </p><a style={{marginLeft:"3px"}} href="/contact">Contact</a><p style={{marginLeft: 5}}></p></div>
        </div>
      </div>
      </Container>
      </div>
    )
  }
}