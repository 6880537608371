import React from 'react'

export default function ExhibitionHeader(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })}
      var doit;
      window.onresize = function(){
        clearTimeout(doit);
        console.log(props.parent.current.clientWidth)
        doit = setTimeout(handleResize, 100);
      };
  })
  return (
    <div>
    <div style={{width:`${300 * Math.floor((props.parent.current ? props.parent.current.clientWidth :  window.innerWidth - 200 )/308)}px`, minWidth:"300", borderStyle: "solid"}}>
    {props.text}
  </div>
  </div>
  )
}