import React, { Component } from 'react';
import { Form, Button } from "react-bootstrap"

export default class MultipleImageUploadComponent extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props)
        this.state = {
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        console.log(e.target.files)
        var curArrayLength = this.fileObj.length
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push({url: this.fileObj[0][i], order: i + curArrayLength, image_name: '', image_description : '', id: i + curArrayLength})
        }
        if(this.props.imageCallback) this.props.imageCallback(this.fileArray)
        this.setState({ file: this.fileArray })
        console.log(this.fileArray)
        this.fileObj = []
    }

    handleOrderChange(val, id) {
      var temp = this.state.file
      temp[id].order = val;
      this.props.imageCallback(temp)
      this.setState({file: temp})
      console.log(this.state.file)
    }

    uploadFiles(e) {
        e.preventDefault()
        console.log(this.state.file)
    }

    removeImage(id) {
      console.log(this.fileArray)
      console.log(this.fileObj)
      var tempImages = []
      this.fileArray.forEach(image => {
        console.log("image array id " + image.id )
        console.log("image to remove id " + id)
        if(image.id !== id) tempImages.push(image)
      })
      this.fileArray = tempImages
      console.log(this.fileArray)
      if(this.props.imageCallback) this.props.imageCallback(this.fileArray)
      this.setState({ file: tempImages })
    }

    render() {
        return (
            <form>
              {this.fileArray.length > 0 && (<h2>Images to Upload</h2>)}
                <div className="form-group multi-preview" style={{textAlign: 'left'}}>
                    {(this.fileArray || []).map(({url, image_name, image_description, order, id}) => (
                      <div style={{marginBottom: 10}}>
                        <img style={{maxWidth: 300}} src={URL.createObjectURL(url)} alt="..." />
                        <p style={{marginTop: 0, marginBottom: 0}}>Order</p>
                        <Form.Control
                        style={{maxWidth: 300}} 
                        type='number'
                        defaultValue={order}
                        onChange={e => {      
                          var temp = this.state.file
                          temp[id].order = e.target.value;
                          this.setState({file: temp})
                          console.log(this.state.file)}}
                        placeholder="Image order number"
                        />
                        {!this.props.hideImageName &&
                          <Form.Group>
                          <Form.Label>Image Name</Form.Label>
                        <Form.Control
                        style={{maxWidth: 300}} 
                        type='text'
                        defaultValue={image_name}
                        onChange={e => {      
                          var temp = this.state.file
                          temp[id].image_name = e.target.value;
                          this.setState({file: temp})
                          console.log(this.state.file)}}
                        placeholder="Name"
                        />
                        </Form.Group>}
                        {!this.props.hideImageDescription && <Form.Group>
                          <Form.Label>Image Description</Form.Label>
                          <Form.Control
                            style={{ maxWidth: 300 }}
                            type='text'
                            defaultValue={image_description}
                            onChange={e => {
                              var temp = this.state.file
                              temp[id].image_description = e.target.value;
                              this.setState({ file: temp })
                              console.log(this.state.file)
                            }}
                            placeholder="Description"
                          />
                        </Form.Group>}
                        <Button variant="danger" style={{marginTop: 5}} onClick={() => this.removeImage(id)}>Remove Image</Button>
                      </div>

                    ))}
                </div>

                <div className="form-group" style={{textAlign: 'left'}}>
                  <p style={{marginTop: 0, marginBottom: 0}}>Images to upload</p>
                  <input type="file" className="form-control" accept="image/*" onChange={this.uploadMultipleFiles} multiple />
                </div>
            </form >
        )
    }
}