import React, {Component} from "react";
import StripeContainer from "../billing/stripe.container";
import ShopItems from "./shop.items.component";
import { Modal, Button } from 'react-bootstrap'
import { Fab, Action } from 'react-tiny-fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import 'react-tiny-fab/dist/styles.css';


export default class Shop extends Component {
  constructor(props) {
    super();
    this.state = {
      amount: 0,
      cart: {},
      shopItems: null,
      showItemAddedModal: false
    }
    this.addItemToCartCallback = this.addItemToCartCallback.bind(this)
    this.cartUpdated = this.cartUpdated.bind(this)
    this.itemAddedToCartModalClose = this.itemAddedToCartModalClose.bind(this)
  }

  componentDidMount() {
    this.getShopItems()
    var storedStateCart = localStorage.getItem('cart')
    if (storedStateCart) {
      this.setState({cart: JSON.parse(localStorage.getItem('cart'))})
      this.cartUpdated()
    }
  }

  getShopItems() {
    fetch(`${process.env.REACT_APP_API_URL}/saleItems`, {mode: 'cors'})
    .then(response => response.json())
    .then(res => {
      this.setState({ shopItems: res }, () => {
        this.cartUpdated();
    })
    })
    .catch((error) => {
      console.log("there was an error")
    })
  }

  cartUpdated() {
    var amount = 0
    localStorage.setItem('cart', JSON.stringify(this.state.cart))
    Object.keys(this.state.cart).map(function(keyName, keyIndex) {
      this.state.shopItems.map(({id, price}) => {
        if (id == keyName) {
          amount += price * this.state.cart[keyName]
        }
      })
      this.setState({amount: amount})
    }.bind(this))
  }

  itemAddedToCartModalClose() {
    this.setState({showItemAddedModal: false})
  }

  addItemToCartCallback(productId, itemTypeId) {
    var cartClone = JSON.parse(JSON.stringify(this.state.cart))
    
      if (cartClone[productId] && cartClone[productId][itemTypeId]) {
        cartClone[productId][itemTypeId].quantity += 1
      } else if (cartClone[productId]) {
        cartClone[productId][itemTypeId] = {quantity: 1} 
      } else {
        cartClone[productId] = {}
        cartClone[productId][itemTypeId] = {quantity: 1} 
      }
    
    this.setState({cart: cartClone}, () => {
      this.cartUpdated();
    })
    this.setState({showItemAddedModal: true})
  }

  redirectToCart() {
    window.location.pathname = '/cart'
  }

  render() {
  var cartItems = Object.values(this.state.cart).map(val => Object.values(val))
  var anyItemsInCart = !!cartItems.find(item => Object.values(item).find(t => t.quantity == 1))
  return (
    <div className="container">
      {anyItemsInCart && !this.state.showItemAddedModal && <Fab
        style={	{ bottom: 24, left: 0 }}
        icon={<FontAwesomeIcon icon={faShoppingCart} />}
        onClick={this.redirectToCart}
      />}
      <i className="fas fa-shopping-cart"></i>
        <Modal centered={true} enforceFocus={false} show={this.state.showItemAddedModal} onHide={this.itemAddedToCartModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Item Added to Cart</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.itemAddedToCartModalClose}>
            Keep Shopping
          </Button>
          <Button variant="primary" onClick={this.redirectToCart}>
            Go to Cart
          </Button>
        </Modal.Footer>
      </Modal>
      {this.state.shopItems && <ShopItems shopItems={this.state.shopItems} addItemToCartCallback={this.addItemToCartCallback}/>}
    </div>
  );
}
}