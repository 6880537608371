import React, {useEffect, useState} from "react";
import NewsEditor from "../admin/news.editor.component";
import PageEditor from "../admin/page.editor.component";
import BiographyEditor from "../admin/biography.editor.component";
import ExhibitionEditor from "../admin/exhibition.editor.component"
import ContactEditor from "./contact.editor.component";
import SiteSettings from "./site.settings.component";
import ShopEditor from "./shop.editor.component";
import { Button } from "react-bootstrap"

function AdminContainer() {
  const [newsPageRoutes, setNewsPageRoutes] = useState(null)
  const [imagePageRoutes, setImagePageRoutes] = useState(null)

    useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/pages`)
    .then(response => response.json())
      .then(res => {
        var newsPages = []
        var imagePages = []
        console.log(res)
        res.map(({ id, header_name, name }) => {
          if (name === 'Posts') newsPages.push({ id: id, header_name: header_name })
          if (name === 'Images') imagePages.push({ id: id, header_name: header_name })
        })
        setNewsPageRoutes(newsPages)
        setImagePageRoutes(imagePages)
    })
  }, [])

  return newsPageRoutes && (
    <div className="body-container">
      <div style={{ display: "flex", marginTop: 10, marginBottom: 20, justifyContent: 'space-between' }}>
        <h1>Welcome     👩🏼‍🎨🎨 </h1>
              <Button variant="secondary" onClick={() => window.location.pathname = "/adminorders"} style={{justifyContent:'right'}}>See Shop Orders</Button>
        </div>
      <PageEditor />
      {newsPageRoutes && newsPageRoutes.map(({ id, header_name }) => {
        return <NewsEditor pageId={id} pageName={header_name}/>
      })}
      {imagePageRoutes && imagePageRoutes.map(({ id, header_name }) => {
        return <ExhibitionEditor pageId={id} pageName={header_name}/>
      })}
      <BiographyEditor/>
      <ContactEditor />
      <ShopEditor />
      {newsPageRoutes && <SiteSettings pages={newsPageRoutes}/>}
      </div>
    )
}

export default AdminContainer