import React from "react"
import { Form, Col, OverlayTrigger } from "react-bootstrap"
import Tooltip from "react-bootstrap/Tooltip";

export default class TextBoxEditor extends React.Component {
  constructor(props) {
    super();
    this.state = {
      name: props.name,
      text: props.text,
      id: props.identifier
    };
  }

  textChanged(value) {
    this.setState({text: value})
    if (this.props.textChangedCallback) {
      this.props.textChangedCallback(this.state.id, this.state.name, value)
    }
  }

  render() {
    console.log(this.props.toolTipText)
    return (

      <div>
        {this.props.toolTipText && <OverlayTrigger placement="top" overlay={<Tooltip>{this.props.toolTipText}</Tooltip>}>
          <Col md>
            <Form.Label>{this.state.name}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              defaultValue={this.state.text}
              onChange={e => this.textChanged(e.target.value)}
            />
          </Col>
        </OverlayTrigger>}
        {!this.props.toolTipText && <Col md>
          <Form.Label>{this.state.name}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            defaultValue={this.state.text}
            onChange={e => this.textChanged(e.target.value)}
          />
        </Col>}
    </div>
    )
  }
}