import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap"
import ExhibitionCarousel from "./exhibition.carousel.component";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


export default class ExhibitionModal extends Component {

  constructor(props) {
    super();
    this.state = {
      apiResults: {},
      isLoaded: false
      }
    };

    async componentDidMount() {
      this.getExhibtionsResults();
      window.scrollTo(0, 0);
      disableBodyScroll(document.querySelector('#ModalContainer'));
    }  


  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }
    

  getExhibtionsResults() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: this.props.ExhibitionId }),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/exhibition`, requestOptions)
      .then(response => response.json())
      .then(res => {
        res.sort((a, b) => a.ordering - b.ordering);
        this.setState({ apiResults: res })
        this.setState({ isLoaded: true })
      })
      .catch((error) => {
        console.log("there was an something")
      })
  }

  render() {
    return (
      <div id="ModalContainer">
      <Modal.Dialog size='lg'>
        <br/>
        <Modal.Body>
          { this.state.isLoaded && <ExhibitionCarousel data={this.state.apiResults}/>}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => this.props.CloseHandler()} variant="secondary">Close</Button>
        </Modal.Footer>
      </Modal.Dialog>
      </div>
    )

  }
}