import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import MultipleImageUploadComponent from "../image.upload.component";
import { FormControl, InputGroup, Form, Row, Table } from "react-bootstrap"
import Loader from "react-loader-spinner";
import { SimpleModal } from "../modal.simple.component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
var moment = require('moment-timezone')

export default class ShopEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      shopItems: [],
      loaded: false,
      currentEditId: 0,
      editing: false,
      shopItemName: '',
      shopItemDescription: '',
      shopItemRemaining: '',
      shopItemPrice: '',
      shopItemTypes: [{ size: 'a4', price: 10}],
      shopItemShortDescription: '',
      shopItemCanShip: false,
      shopItemShippingCost: '',
      imagesEditing: [],
      imagesToUpload: [],
      showLoader: false,
      showModal: false,
      modalTitle: '',
      modalBody: ''
    };
    this.setCurrentEdit = this.setCurrentEdit.bind(this);
    this.imageUpload = this.imageUpload.bind(this);
  }

  async componentDidMount() {
    await this.getShopItems();
  }

  getShopItem(id) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: id }),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/saleItem`, requestOptions)
      .then(response => response.json())
      .then(res => {
        var images = []
        res.map(({image_bin, display_order, id, image_id}) => {
          images.push({image: image_bin, id: id, display_order: display_order, changed: false, image_id: image_id})
        })
        this.setState({imagesEditing: images})
        this.setState({editing: true})
        console.log(this.state.imagesEditing)
      })
      .catch((error) => {
        console.log("there was an something")
      })
  }

  getShopItems() {
    fetch(`${process.env.REACT_APP_API_URL}/saleItems`, {mode: 'cors'})
    .then(response => response.json())
    .then(res => {
      this.setState({ shopItems: res })
      this.setState({loaded: true})
    })
  }

  imageUpload(files) {
    console.log(files)
    this.setState({imagesToUpload: files})
  }

  uploadImageCallBack(order, url, shopItemId) {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ev => {
          resolve(ev.target.result)
        }
        return reader.readAsDataURL(url)
      }).then(result => {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: localStorage.getItem("token"), imageBinary: result, shop_item_id: shopItemId, display_order: order })
        };
        return fetch(`${process.env.REACT_APP_API_URL}/uploadSaleItemImages`, requestOptions).catch(error => this.showModal("There was an error"))
      }).then(response => { return response.json() })
      .then(data => { 
        return { data: { link: data.url }} })
  }

  deleteImage(id) {
    console.log(this.state.imagesEditing)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({token: localStorage.getItem("token"), id: id}),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/deleteSaleItemImage`, requestOptions)
      .then(response => {
        if(response.status === 200) {
          var tempArray = []
          this.state.imagesEditing.forEach(image => {
            if(image.image_id !== id) {
              tempArray.push(image);
            }
          })
          this.setState({imagesEditing: tempArray})
          this.showModal("Entry deleted successfully")
        }
        else {
          this.showModal("There was an issue");
        }
      })
      .catch((error) => {
        this.showModal("There was an error")
        console.log(error)
      })
  }

  deleteEntry() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({token: localStorage.getItem("token"), id: this.state.currentEditId }),
      mode: 'cors' 
  };

    fetch(`${process.env.REACT_APP_API_URL}/deleteSaleItem`, requestOptions)
      .then(response => {
        if(response.status === 200) {
          var tempArray = []
          this.state.shopItems.forEach(exhibition => {
            if(exhibition.id !== this.state.currentEditId) {
              tempArray.push(exhibition);
            }
          })
          this.setState({shopItems: tempArray})
          this.setCurrentEdit(0)
          this.showModal("Entry deleted successfully")
        }
        else {
          this.showModal("There was an issue");
        }
      })
      .catch((error) => {
        this.showModal("There was an error")
        console.log(error)
      })
  }

  submitForm() {
    this.setState({showLoader: true})
    if(this.state.editing) {
      var updateArray = []
      var promisesArray = []
      var errors = []
      this.state.imagesEditing.map(({ id, display_order, changed }) => {
        if(changed) {
          updateArray.push({id: id, display_order: display_order})
        }
      } )
      if(updateArray.length > 0) {
      const imagesRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'token': localStorage.getItem("token")},
        body: JSON.stringify(updateArray)
      };
      promisesArray.push(fetch(`${process.env.REACT_APP_API_URL}/updateSaleItemImages`, imagesRequestOptions)
      .then(res => res.json()).then(e => errors.push(e)))
      }
      
      const exhibitionRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token: localStorage.getItem("token"), item_types: JSON.stringify(this.state.shopItemTypes), short_description: this.state.shopItemShortDescription, title: this.state.shopItemName, description: this.state.shopItemDescription, price: this.state.shopItemPrice, remaining: this.state.shopItemRemaining, shipping_cost: this.state.shopItemShippingCost, can_ship: this.state.shopItemCanShip, id: this.state.currentEditId })
      }
      promisesArray.push(fetch(`${process.env.REACT_APP_API_URL}/updateSaleItem`, exhibitionRequestOptions)
      .then(res => res.json()).catch(e => errors.push(e)))

      this.state.imagesToUpload.map(({order, url }) => { 
        promisesArray.push(this.uploadImageCallBack(order, url, this.state.currentEditId).catch(e => errors.push(e)))
       })
       Promise.all(promisesArray).then(() => { 
         this.setState({showLoader: false})
         this.showModal("Upload complete")
        })
    }
    else {
      var promises = []
      //if no images provided alert some message
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: localStorage.getItem("token"), item_types: JSON.stringify(this.state.shopItemTypes), title: this.state.shopItemName, remaining: this.state.shopItemRemaining, price: this.state.shopItemPrice, short_description: this.state.shopItemShortDescription, description: this.state.shopItemDescription, shipping_cost: this.state.shopItemShippingCost, can_ship: this.state.shopItemCanShip})
      };
      fetch(`${process.env.REACT_APP_API_URL}/createSaleItem`, requestOptions)
      .then(res => res.json())
      .then(response => {
        console.log("STATE OF IMAGES AFTER RESPONSE FROM CREATE EXHIBITION")
        console.log(this.state.imagesToUpload)
        this.state.imagesToUpload.map(({order, url }) => { 
          console.log(response)
          promises.push(this.uploadImageCallBack(order, url, response.id))
          console.log(promises)
         })
         Promise.all(promises).then(() => {
           this.getShopItem(response.id)
           this.setState({showLoader: false })
           this.showModal("Upload complete")
          })
      })
      .catch(error => this.showModal(error))
    }
  }

  setCurrentEdit(editId) {
    console.log(editId)
    if(editId === 0) {
      console.log(this.state.shopItems)
      this.setState({editing: false, shopItemTypes: [], shopItemName: '', shopItemDescription: '', shopItemPrice: '', shopItemRemaining: '', shopItemShortDescription: '', shopItemCanShip: false, shopItemShippingCost: ''})
    }
    else {
      this.state.shopItems.map(({id, title, description, price, remaining, short_description, can_ship, shipping_cost, item_types }) => {
          if(id === editId) {
            this.setState({shopItemTypes: JSON.parse(item_types), shopItemName: title, shopItemDescription: description, shopItemRemaining: remaining, shopItemPrice: price, shopItemShortDescription: short_description, shopItemCanShip: can_ship, shopItemShippingCost: shipping_cost})
            this.getShopItem(id)
          }
      })
    }
    this.setState({currentEditId : editId})
  }

    showModal(body) {
    if (this.state.showModal) {
      this.setState({ modalBody: this.state.modalBody + ', ' + body })
    }
    else {
      this.setState({ showModal: true, modalTitle: 'Alert', modalBody: body })
    }
  }

  modalClosedCallback() {
    this.setState({showModal: false, modalTitle: '', modalBody: ''})
  }

  render() {
    return (
      <div>
        <SimpleModal show={ this.state.showModal } title={this.state.modalTitle} body={this.state.modalBody} modalClosedCallback={this.modalClosedCallback.bind(this)}  />
        {this.state.loaded && (
          <div style={{borderStyle: "groove", padding: 10, borderRadius: 10, marginTop: "50px"}}>
            <h2>Edit {this.props.pageName ? this.props.pageName : "Shop"} Page</h2>
        <Dropdown style={{margin:"5px"}}>
          <Dropdown.Toggle style={{margin:"5px"}} variant="success" id="dropdown-basic">
            Edit existing entry
          </Dropdown.Toggle>
          <Button onClick={() => this.setCurrentEdit(0)}>New Entry</Button>

          <Dropdown.Menu>
               {this.state.shopItems.map(({ id, title, remaining }) => (
                <div>
                  <Dropdown.Item onClick={() => this.setCurrentEdit(id)}>{title} ({remaining})</Dropdown.Item>
                </div>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        <div  style={{textAlign: "left"}}>
              <p style={{ marginTop: 0, marginBottom: 0 }}>{this.props.pageName ? this.props.pageName : "Item"}  Name</p>
                          <OverlayTrigger placement="top" overlay={<Tooltip>Title of the product, e.g. name of the painting/item.</Tooltip>}>
        <FormControl
          style={{maxWidth: 300}} 
          type='text'
          value={this.state.shopItemName}
          onChange={e => this.setState({ shopItemName: e.target.value })}
          placeholder="Name"
              />
              </OverlayTrigger>
              <Form.Group style={{maxWidth: 300, marginBottom: 10}} >
            </Form.Group>
              <InputGroup>
                  <OverlayTrigger placement="top" overlay={<Tooltip>If this is not checked, the item will only be available for pickup - if checked, item is shipped only.</Tooltip>}>
                    <Form.Check style={{marginRight: 20}} type="checkbox" checked={this.state.shopItemCanShip} onChange={() => this.setState({shopItemCanShip: !this.state.shopItemCanShip})} label="Item Can Ship" />
                  </OverlayTrigger>
                  {this.state.shopItemCanShip && (
                    <div style={{ display: 'flex', maxWidth: 300, marginBottom: 10 }}>
                  <InputGroup.Text>$</InputGroup.Text>
          <FormControl
            type='number'
            value={this.state.shopItemShippingCost !== '' ? this.state.shopItemShippingCost/100 : this.state.shopItemShippingCost}
            onChange={e => this.setState({ shopItemShippingCost: e.target.value !== '' ? e.target.value*100 : e.target.value })}
            placeholder="Shipping Cost"
                /></div>)}
              </InputGroup> 
              <p style={{ marginTop: 0, marginBottom: 0 }}>{this.props.pageName ? this.props.pageName : "Item"}  Long Description</p>
            <OverlayTrigger placement="top" overlay={<Tooltip>A longer description of the item, this appears when the user selects a specific item to view more details.</Tooltip>}>

              <FormControl
              as="textarea"
              rows={3}
              value={this.state.shopItemDescription}
              onChange={e => this.setState({ shopItemDescription: e.target.value })}
              />
            </OverlayTrigger>
              <p style={{ marginTop: 0, marginBottom: 0 }}>{this.props.pageName ? this.props.pageName : "Item"}  Short Description</p>
                          <OverlayTrigger placement="top" overlay={<Tooltip>Short descripion about the product, (e.g. size). This appears on the card view on the main screen of the shop.</Tooltip>}>
            <FormControl
              as="textarea"
              rows={1}
              value={this.state.shopItemShortDescription}
              onChange={e => this.setState({ shopItemShortDescription: e.target.value })}
              />
              </OverlayTrigger>
               <p style={{ marginTop: 0, marginBottom: 0 }}>{this.props.pageName ? this.props.pageName : "Item"}  Types</p>
                  <Table striped bordered hover>
      <thead>
        <tr>
          <th>Size</th>
          <th>Price</th>
          <th>Stock Available</th>
          <th>Default</th>
        </tr>
      </thead>
 
                {this.state.shopItemTypes.map( (itemType, index ) => (
                    <tbody>
                      <td>
                      <FormControl
                        onChange={e => {
                          let clone = JSON.parse(JSON.stringify(this.state.shopItemTypes))
                          clone[index].size = e.target.value
                          this.setState({ shopItemTypes: clone})}
                        } 
          type='text'
          value={this.state.shopItemTypes[index].size}
          placeholder="Size"
              />
                    </td>
                    <td>
                      <FormControl
                        onChange={e => {
                          let clone = JSON.parse(JSON.stringify(this.state.shopItemTypes))
                          clone[index].price = e.target.value
                          this.setState({ shopItemTypes: clone})}
                        } 
          type='number'
          value={this.state.shopItemTypes[index].price}
          placeholder="Price"
              />
                    </td>
                    <td>
                      <FormControl
                        onChange={e => {
                          let clone = JSON.parse(JSON.stringify(this.state.shopItemTypes))
                          clone[index].remaining = e.target.value
                          this.setState({ shopItemTypes: clone})}
                        } 
          type='number'
          value={this.state.shopItemTypes[index].remaining}
          placeholder="Remaining"
              />
                      </td>
                    <td>
                      <Form.Check
                        disabled={this.state.shopItemTypes.find((type, i) => type.default && index !== i)}
                        type="checkbox"
                        checked={this.state.shopItemTypes[index].default === true}
                        onChange={e => {
              let clone = JSON.parse(JSON.stringify(this.state.shopItemTypes))
                          clone[index].default = !clone[index].default
                          this.setState({ shopItemTypes: clone})}
            }
          />
                      </td>
                      </tbody>
                ))}

              </Table>
              <Button onClick={() => {
                let clone = JSON.parse(JSON.stringify(this.state.shopItemTypes))
                          clone.push({id: clone.length, price: 0, size: '', remaining: 1})
                          this.setState({ shopItemTypes: clone})
              }}>Add Item Type</Button>
            </div>

        {!this.state.editing && <MultipleImageUploadComponent hideImageDescription hideImageName imageCallback={this.imageUpload} />}
        {this.state.editing && (
          <div style={{textAlign: 'left'}}>
            <MultipleImageUploadComponent hideImageDescription hideImageName imageCallback={this.imageUpload} />
            <h2>Existing Images</h2>
              {this.state.imagesEditing.map(({ image, id, image_id, display_order }) => (
                <div style={{margin: 5, marginBottom: 20}}>
                    <img style={{maxWidth: 300}} src={image} alt="loading"/>
                    <p style={{marginTop: 0, marginBottom: 0}}>Order</p>
                        <FormControl
                        style={{maxWidth: 300}} 
                        type='number'
                        value={display_order}
                        onChange={e => {
                          var temp = this.state.imagesEditing
                          var tempEntity = temp.find(entity => entity.image_id === image_id);
                          tempEntity.ordering = e.target.value;
                          tempEntity.changed = true;
                          this.setState({imagesEditing : temp})
                          }}
                        placeholder="Image order number"
                        />
                        {this.state.editing && <Button variant="danger" style={{marginTop: 5}} onClick={() => this.deleteImage(image_id)}>Remove Image</Button>}
                </div>
              ))}
          </div>
        )}
            {this.state.showLoader &&       
      <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible={true}
      /> }
        {!this.state.showLoader && <Button style={{marginTop: 5}} onClick={() => this.submitForm()}>Submit</Button>}
        {this.state.editing && !this.state.showLoader && <Button variant="danger" style={{marginTop: 5, marginLeft: 5}} onClick={() => this.deleteEntry()}>Delete</Button>}
        </div>
        )}
      </div>
    )
  }
}