import React, {Component} from "react";
import { Card, Button, Row } from "react-bootstrap";
import { ShopItemModal } from "./shop.items.modal.component";

export default class ShopItems extends Component {
  constructor(props) {
    super()
    this.state = {
      items: null,
      displayModal: false,
      productSelected: null,
      currentSelectedItem: null
    }
    this.addItemToCart.bind(this)
  }

  componentDidMount() {
    this.setState({items: this.props.shopItems})
  }

  handleClick(product) {
    this.setState({ displayModal: true, productSelected: product })
  }

  isSoldOut(productId, itemTypeId) {
    var soldOut = false
    if(this.state.items) {
      var cart = JSON.parse(localStorage.getItem('cart'))
      var itemsInCart = 0
      if (cart[productId] && cart[productId][itemTypeId]) {
        itemsInCart = cart[productId][itemTypeId].quantity
      }
      var shopItem = this.state.items.find(shopItem => shopItem.id == productId)
      const itemType = JSON.parse(shopItem.item_types).find(itemType => itemType.id == itemTypeId)
      return (parseInt(itemType?.remaining || 0) - itemsInCart) <= 0
    }
    return soldOut;
  }

  itemCount(productId, itemTypeId) {
    var cart = JSON.parse(localStorage.getItem('cart'))
    if (cart[productId] && cart[productId][itemTypeId]) {
      return cart[productId][itemTypeId].quantity
    } else {
      return 0
    }
  }

  addItemToCart(productId, itemTypeId) {
    this.props.addItemToCartCallback(productId, itemTypeId)
  }

  createProducts() {
    const chunk = (arr, chunkSize = 1, cache = []) => {
      const tmp = [...arr]
      if (chunkSize <= 0) return cache
      while (tmp.length) cache.push(tmp.splice(0, chunkSize))
      return cache
    }

    const productsChunks = chunk(this.state.items, 100);

    const rows = productsChunks.map((productChunk, index) => {
      const productsCols = productChunk.map((product, index) => {
          return (
            <Card key={index} style={{ margin: 10, padding: 5, width: '18rem' }}>
              <Card.Img onClick={() => (this.handleClick(product))} style={{ height: 250, cursor: "pointer" }} variant="top" src={product.image_bin} />
            <Card.Body>
                <Card.Title>{product.title}</Card.Title>
                {this.state.currentSelectedItem && this.state.currentSelectedItem.productId === product.id &&  (<Card.Subtitle>${this.state.currentSelectedItem.itemType.price}</Card.Subtitle>)}
              <Card.Text>
                {product.short_description}
                </Card.Text>
                {JSON.parse(product.item_types).map(itemType => {
                  var soldOut = this.isSoldOut(product.id, itemType.id)
                  var isSelected = this.state.currentSelectedItem && this.state.currentSelectedItem.productId === product.id && this.state.currentSelectedItem.itemType.id === itemType.id
                  return (<Button disabled={soldOut} variant={soldOut ? 'outline-danger' : isSelected ? 'primary' : 'outline-dark'} onClick={() => this.setState({ currentSelectedItem: { productId: product.id, itemType: itemType }})} style={{ margin: '3px' }}>
                    <div>
                    {itemType.size}
                    </div>
                    {this.itemCount(product.id, itemType.id) > 0 &&
                      <div style={{fontSize: '10px'}}>
                        {this.itemCount(product.id, itemType.id)} In Cart
                      </div>}
                    {soldOut &&
                      <div style={{fontSize: '12px', fontWeight: 800}}>
                        Sold Out
                      </div>}
                  </Button>)
                })}
              {this.state.currentSelectedItem && !this.isSoldOut(this.state.currentSelectedItem.productId, this.state.currentSelectedItem.itemType.id) && this.state.currentSelectedItem.productId === product.id && (<Button style={{marginLeft: "auto", marginRight: "auto", display: "block", marginBottom: "5px"}} onClick={() => ( this.addItemToCart(this.state.currentSelectedItem.productId, this.state.currentSelectedItem.itemType.id)) } variant="outline-primary">Add To Cart</Button>)}
            </Card.Body>
          </Card>
        );
      });
      return <Row key={index}>{productsCols}</Row>
  });
  return rows;
  }

  render() {
    return(
      <div>
        {this.state.items && this.createProducts()}
        {this.state.displayModal &&
          <ShopItemModal
            product={this.state.productSelected}
            addItemToCartCallback={(productId, itemTypeId) => {
              this.addItemToCart(productId, itemTypeId)
              this.setState({ displayModal: false })
            }}
            modalClosedCallback={() => this.setState({ displayModal: false })} />}
      </div>
    )
  }

}