function isLogin() {
  var authenticated = true;
  var token = localStorage.getItem("token");
  if(token == null) {
   authenticated = false;
  }
  fetch(`${process.env.REACT_APP_API_URL}/isAuthenticated`, {
    mode: 'cors',
    method: "POST",
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({'token': token})})
  .then(response => {
  if(!response.status === 200) authenticated = false;
  }).catch((error) => {
  authenticated = false;
 })

 return authenticated;
}

module.exports = {
  isLogin: isLogin
}