import React, { useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";
import {Modal,Button,Form,Row,Col,ListGroup,Spinner} from 'react-bootstrap'
import Loader from "react-loader-spinner";


export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [saleItems, setSaleItems] = useState(null)
  const [amount, setAmount] = useState(0)
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')))
  const [showCartValidation, setShowCartValidation] = useState(false)
  const [cartValidationMessage, setCartValidationMessage] = useState([])
  const [showPaymentFailed, setShowPaymentFailed] = useState({show: false, error: ''})
  const [form, setForm] = useState({})
  const [showPayButton, setShowPayButton] = useState(true)
  const [addressData, setAddressData] = useState([])
  const [isAddressSelected, setIsAddressSelected] = useState(false)
  const [isAddressLoading, setIsAddressLoading] = useState(false);


  useEffect(() => {
    if(saleItems == null) {
      fetch(`${process.env.REACT_APP_API_URL}/saleItems`, {mode: 'cors'})
      .then(response => response.json())
      .then(res => {
      setSaleItems(res)
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }, [])

  useEffect(() => {
    if (cart && Object.keys(cart).length <= 0) window.location.pathname = '/shop'
    else if (saleItems) {
    var computedAmount = 0
    var messages = []
    Object.keys(cart).map(function(keyName, keyIndex) {
      saleItems.map(({ id, title, price, item_types, remaining, can_ship, shipping_cost }) => {
        if (id == keyName) {
          var itemTypes = JSON.parse(item_types)
          itemTypes.map(itemType => {
            if (!cart[keyName][itemType.id]) return

            if (cart[keyName][itemType.id].quantity > itemType.remaining) {
              setShowCartValidation(true)
              messages.push(`${cart[keyName][itemType.id].quantity - itemType.remaining}x "${title}"`)
              if (remaining === 0) {
                delete (cart[keyName][itemType.id].quantity)
                return
              }
              else {
                cart[keyName][itemType.id].remaining = remaining
              }
          }

          computedAmount += itemType.price * cart[keyName][itemType.id].quantity * 100
          if (can_ship && shipping_cost != null && shipping_cost != 0) {
            computedAmount += shipping_cost * cart[keyName][itemType.id].quantity
          }
          })
        }
      })
      setCartValidationMessage(messages)
      setAmount(computedAmount)
      localStorage.setItem('cart', JSON.stringify(cart))
    })
  }
  }, [saleItems, cart])

  const handleSubmit = async (event) => {
    setShowPayButton(false)
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        // Include any additional collected billing details.
        name: form.name,
      },
    });

    stripePaymentMethodHandler(result);
  };

function stripePaymentMethodHandler(result) {
  if (result.error) {
    console.log(result.error)
    setShowPaymentFailed({show: true, error: result.error.message})
    setShowPayButton(true)
    // Show error in payment form
  } else {
    // Otherwise send paymentMethod.id to your server (see Step 4)
    fetch(`${process.env.REACT_APP_API_URL}/stripe/charge`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        payment_method_id: result.paymentMethod.id,
        amount: amount,
        orderDetails: {
          firstName: form.firstName,
          lastName: form.lastName,
          emailAddress: form.contactEmail,
          contactPhone: form.contactPhone,
          streetAddress: form.addressStreet,
          addressSuburb: form.addressSuburb,
          addressCity: form.addressCity,
          addressPostcode: form.addressPostcode
        },
        cart: cart
      })
    }).then(function(result) {
      // Handle server response (see Step 4)
      result.json().then(function(json) {
        handleServerResponse(json);
      })
    });
  }
}
  
  const fetchAddress = (e) => {
    setAddressData([])
    setIsAddressSelected(false)
    setIsAddressLoading(true)
    const nameValue = e.target.value
    setForm({ ...form, addressStreet: nameValue })
    if (nameValue !== '') {
      fetch(`${process.env.REACT_APP_API_URL}/getAddress`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address: nameValue })
      }).then(res => res.json()).then(response => {
        setAddressData(response)
        setIsAddressLoading(false)
      }).catch((error) => {
        console.log(error)
      })
    }
    else {
      setAddressData([]);
      setIsAddressLoading(false)
    }
  }

  const onNameSelected = (selected) => {
    setIsAddressSelected(true)
    setAddressData([]);
    setForm({ ...form, addressStreet: selected.Street, addressSuburb: selected.Suburb, addressCity: selected.Town ? selected.Town : selected.Region, addressPostcode: selected.PostCode })
  };

function handleServerResponse(response) {
  if (response.error) {
    // Show error from server on payment form
    setShowPayButton(true)
    setShowPaymentFailed({show: true, error: response.error})
  } else if (response.requires_action) {
    // Use Stripe.js to handle required card action
    stripe.handleCardAction(
      response.payment_intent_client_secret
    ).then(result => handleStripeJsResult(result, response.order_key));
  } else {
    window.location.pathname = "/orderconfirmation/" + response.order_key
    setShowPayButton(true)
  }
}

function handleStripeJsResult(result, orderKey) {
  if (result.error) {
    fetch(`${process.env.REACT_APP_API_URL}/stripe/charge`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ error: result.error.message, order_key: orderKey })
    })
    setShowPaymentFailed({show: true, error: result.error.message})
    setShowPayButton(true)
  } else {
    // The card action has been handled
    // The PaymentIntent can be confirmed again on the server
    fetch(`${process.env.REACT_APP_API_URL}/stripe/charge`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ payment_intent_id: result.paymentIntent.id, order_key: orderKey })
    }).then(function(confirmResult) {
      return confirmResult.json();
    }).then(handleServerResponse);
  }
}

const cartValidationModal = () => { 
  return (
  <Modal centered={true} enforceFocus={false} show={showCartValidation} onHide={() => setShowCartValidation(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Some items were removed from your cart, as they have since sold out</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {cartValidationMessage.map((message) => {
      return <div>{message}<span style={{color: "red"}}> Removed</span></div>
    })}
    </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowCartValidation(false)}>
      Continue
    </Button>
    <Button variant="primary" onClick={() => window.location.pathname="/cart"}>
      Go to Cart
    </Button>
  </Modal.Footer>
</Modal>
)}

const paymentFailedModal = () => { 
  console.log(showPaymentFailed.error)
  return (
  <Modal centered={true} enforceFocus={false} show={showPaymentFailed.show} onHide={() => setShowPaymentFailed({show: false})}>
  <Modal.Header closeButton>
    <Modal.Title>Payment failed</Modal.Title>
  </Modal.Header>
  <Modal.Body>Bank response: {showPaymentFailed.error}</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowPaymentFailed({show: false})}>
      Continue
    </Button>
  </Modal.Footer>
</Modal>
)}


  return (
    <div className="container" style={{border: "1px solid rgba(0,0,0,.125)", borderRadius: 5, paddingBottom: "10px"}}>
      <div style={{borderBottom: "1px solid lightgray", padding: "10px", marginBottom: "10px"}}>
      <span style={{fontSize: 24}}>Checkout</span>
      </div>
      <Form style={{padding: "10px", textAlign: "left"}} onSubmit={handleSubmit}>
      <div style={{marginTop:"20px", marginBottom: "20px"}}>        <div style={{display: "inline-block"}} className="numberCircle">1</div>
          <span style={{fontSize: "18px", fontWeight: "bold"}}> Contact Details</span>
          </div>
      <Row style={{marginBottom: "20px"}}>
          <Col md={3}>
            <Form.Label>First Name</Form.Label>
            <Form.Control 
            required
              type="text" 
              placeholder="Your First Name"
              value={form.firstName}
              onChange={e => setForm({ ...form, firstName: e.target.value })} 
            />
            </Col>
            <Col md={3}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
              required
              type="text" 
              placeholder="Your Last Name"
              value={form.lastName}
              onChange={e => setForm({ ...form, lastName: e.target.value })} 
            />
            </Col>
            <Col md={4}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control 
              required
              type="email" 
              placeholder="Your Email Address"
              value={form.contactEmail}
              onChange={e => setForm({ ...form, contactEmail: e.target.value })} 
            />
            </Col>
            <Col md={2}>
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              required
              type="text" 
              placeholder="Your Contact Number"
              value={form.contactPhone}
              onChange={e => setForm({ ...form, contactPhone: e.target.value })} 
            />
            </Col>
        </Row>
        <div style={{marginTop:"20px", marginBottom: "20px"}}>
        <div style={{display: "inline-block"}} className="numberCircle">2</div>
          <span style={{fontSize: "18px", fontWeight: "bold"}}> Billing Details</span>
        </div>
        <Row style={{marginBottom: "20px"}}>
          <Col md={4}>
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              onFocus={() => setIsAddressSelected(false)}
              onBlur={() => setIsAddressSelected(true)}
              required
              placeholder="Street Address"
              type="text"
              autoComplete="off"
              onChange={fetchAddress}
              value={form.addressStreet}
        />
        <ListGroup style={{position: 'absolute', zIndex: 10, marginRight: '20px'}} className="typeahead-list-group">
          {!isAddressSelected &&
            addressData.length > 0 &&
            addressData.map((result) => (
              <ListGroup.Item
                key={result.Street}
                className="typeahead-list-group-item"
                onClick={() => onNameSelected(result)}
              >
                {result.Street}, {result.Suburb}, {result.Region}, {result.PostCode}
              </ListGroup.Item>
            ))}
          {!addressData.length && isAddressLoading && (
            <div style={{position: 'absolute', zIndex: 100}} className="typeahead-spinner-container">
              <Spinner animation="border" />
            </div>
          )}
        </ListGroup>
            </Col>
            <Col md={2}>
            <Form.Label>Suburb</Form.Label>
            <Form.Control
              required
              type="text" 
              placeholder="Your Suburb"
              value={form.addressSuburb}
              onChange={e => setForm({ ...form, addressSuburb: e.target.value })} 
            />
            </Col>
            <Col md={2}>
            <Form.Label>City</Form.Label>
            <Form.Control
              required
              type="text" 
              placeholder="Your City"
              value={form.addressCity}
              onChange={e => setForm({ ...form, addressCity: e.target.value })} 
            />
            </Col>
            <Col md={2}>
            <Form.Label>Post Code</Form.Label>
            <Form.Control
              required
              type="text" 
              placeholder="Your Postal Code"
              value={form.addressPostcode}
              onChange={e => setForm({ ...form, addressPostcode: e.target.value })} 
            />
          </Col>
            <Col md={2}>
            <Form.Label>Country</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value="New Zealand"
            />
            </Col>
        </Row>
        <div style={{marginTop:"20px", marginBottom: "20px"}}>
        <div style={{display: "inline-block"}} className="numberCircle">3</div>
          <span style={{fontSize: "18px", fontWeight: "bold"}}> Payment Details</span>
        </div>
        {cartValidationModal()}
      {paymentFailedModal()}
      <div style={{maxWidth: "400px", overflow: "hidden", textAlign: "left"}}>
      <div style={{marginBottom: "5px"}}>Total (NZD): ${amount/100.0}</div>
      <div style={{marginBottom: "5px", fontSize: "10px", color: "gray"}}>Includes GST of: ${(amount/100.0*0.15).toFixed(2)}</div>
        <div style={{border: "1px solid rgba(0,0,0,.125)"}}>
      <CardElement/>
      </div>
      {showPayButton && <Button style={{float: "left"}} type="submit" className="stripe">Pay</Button>}
      <Loader
        type="ThreeDots"
        color="#6772e5"
        height={50}
        width={50}
        visible={!showPayButton}
      /> 
      </div>
      </Form>
      </div>
  );
};
